<template>
  <div class="main mt-1">
    <a-tabs v-model:activeKey="activeKey"  class="text-white mt-4">
      <a-tab-pane  key="1" tab="Claim a chest" disabled>
<!--        <a-result class="roboto" style="color: #fff" title="Coming Soon">-->
<!--          <template #icon>-->
<!--            <img width="200" style="margin: auto" src="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original">-->
<!--          </template>-->
<!--          <template #extra>-->
<!--          </template>-->
<!--        </a-result>-->
        <div class="flex flex-column align-items-center justify-content-center" style="border-radius: 12px 12px 38px 38px;border: 2px solid rgb(55,53,67);
        max-width: 1022px;margin: 0 auto;fill: rgba(0, 0, 0, 0.00);
stroke-width: 3px;
stroke: #FFF;
filter: drop-shadow(0px 13px 0px rgba(0, 0, 0, 0.45));background-color: rgb(15,17,31)">
          <img width="1022" src="../../assets/web/getmoga/openchest1.png">
          <div style="color: #fff">btn</div>
        </div>
        <div class="cards-ul align-items-center">
          <img width="183" src="../../assets/web/getmoga/card1card.png">
          <img class="m-2" width="195" src="../../assets/web/getmoga/card2card.png">
          <img class="m-2" width="203" src="../../assets/web/getmoga/card3card.png">
          <img class="m-2" width="183" src="../../assets/web/getmoga/card4card.png">
          <img width="183" src="../../assets/web/getmoga/card5card.png">
        </div>
        <div class="w-96">
          <div></div>
        </div>

      </a-tab-pane>
      <a-tab-pane key="2" tab="Buy Genesis Moga" force-render>
        <div class="max-width w-100  getmoga-bg">
<!--          <div class="d-flex justify-content-start mt-4">-->
<!--            <div class="claim-chest cursor-pointer">Claim a Chest</div>-->
<!--            <div class="genesis-moga cursor-pointer"><a href="#buy">Buy Genesis Moga</a></div>-->
<!--          </div>-->
          <div class="t-title pt-4">
            Jumpstart your journey<br>
            with Genesis Moga!
          </div>
          <div class="d-flex justify-content-center text-white mt-4">
            Purchase powerful cards and propel your progress
          </div>
                    <div class="d-flex justify-content-center mt-4">
                      <div style="width: 140px;min-height: 37px;background-color: #01c038;border-radius: 8px;
      box-shadow: 0px 8px rgba(0, 0, 0, 0.3), 0 4px #01c038"  class="mr-2 cursor-pointer d-flex align-items-center justify-content-center JungleAdventurer text-white"><a class="text-white flex w-100 align-items-center justify-content-center h-full hover:text-white" href="#buy-ul">Buy Genesis Moga</a></div>
                      <div  style="width: 140px;min-height: 37px;background-color: #7c5e99;border-radius: 8px;
      box-shadow: 0px 8px rgba(0, 0, 0, 0.3), 0 4px #7c5e99" class="cursor-pointer d-flex align-items-center justify-content-center JungleAdventurer text-white"><a href="https://galaxy-games-inc.gitbook.io/welcome-to-monster-galaxy-p2e-1/genesis-moga/genesis-moga-sale-events" target="_blank" class="hover:text-white text-white flex w-100 align-items-center justify-content-center h-full">Learn More</a></div>
                    </div>
          <div class="d-flex align-items-center  text-white mt-4 getmoga-nav">
            <div class="d-flex flex-column nav-item">
              <div class="num text-2xl">{{ totalNft }}</div>
              <div class="txt">TOTAL</div>
            </div>
            <div class="d-flex flex-column nav-item">
              <div class="num text-2xl">{{ totalNft*1-totalSold*1 }}</div>
              <div class="txt">AVAILABLE</div>
            </div>
            <div class="d-flex flex-column nav-item">
              <div class="num text-2xl">{{ totalSold }}</div>
              <div class="txt">SOLD</div>
            </div>
          </div>
          <div class="ends mb-3 mt-4">
<!--            Ends in 00 : 00 : 00-->
          </div>
        </div>
        <div class="buy-module max-width w-11/12 hidden lg:block" style="margin-bottom: 30px">
          <div class="JungleAdventurer text-4xl text-center text-white m-2">
            Portfolio
          </div>
          <div class="three-ul ">
            <div class="three-li" >
              <div class="h-full flex align-items-center justify-content-center hovered">
                <div @click="showDrawer(1)" class="w-1/2 text-white text-center text-2xl p-2 JungleAdventurer cursor-pointer" style="background: #6d5389;border-radius: 22px;box-shadow: 0px 8px rgba(0, 0, 0, 0.3), 0 4px #6d5389">Detail</div>
              </div>
            </div>
            <div class="three-li" >
              <div class="h-full flex align-items-center justify-content-center hovered">
                <div @click="showDrawer(2)" class="w-1/2 text-white text-center text-2xl p-2 JungleAdventurer cursor-pointer" style="background: #6d5389;border-radius: 22px;box-shadow: 0px 8px rgba(0, 0, 0, 0.3), 0 4px #6d5389">Detail</div>
              </div>
            </div>
            <div class="three-li" >
              <div class="h-full flex align-items-center justify-content-center hovered">
                <div @click="showDrawer(3)" class="w-1/2 text-white text-center text-2xl p-2 JungleAdventurer cursor-pointer" style="background: #6d5389;border-radius: 22px;box-shadow: 0px 8px rgba(0, 0, 0, 0.3), 0 4px #6d5389">Detail</div>
              </div>
            </div>
          </div>


        </div>
        <div class="buy-module max-width w-11/12" id="buy-ul" v-if="nft_list.length>0">
          <div class="JungleAdventurer text-4xl text-center text-white m-2">
            buy
          </div>
          <div class="buy-ul" >
            <div class="buy-li" v-for="item in nft_list">
              <div class="t1 text-white text-center m-2">#{{ item.tokenId }}</div>
              <div class="img flex justify-content-center align-items-center" style="width: 170px;height: 266px;border-radius: 8px;margin: auto;overflow: hidden">
                <img  :src="item.image != undefined ? item.image : DEFAULT_IMG">
                <div v-if="item.isSold" class="JungleAdventurer text-white text-2xl absolute" >sold out</div>
              </div>
              <div class="t2 flex flex-row justify-between text-xl ml-2 mr-2">
                <div class="JungleAdventurer text-white" style="color: #56e2b8">{{ item.rarity }}</div>
                <div class="JungleAdventurer text-white">{{ toFormatEthers(item.price) }}&nbsp;GCoin</div>
              </div>
              <div class="btn m-1 flex align-items-center justify-content-center relative">
                <img class="cursor-pointer" @click="buymodal(item)" width="172" src="../../assets/web/getmoga/buybutton.png">
<!--                <div class="text-white" style="position: absolute;top: 0px;right: 20px">-->
<!--                  {{ toFormatEthers(item.price) }}-->
<!--                </div>-->
              </div>
              <div class="t3 text-white text-center " v-show="timeDifference<86400000">
                Expire in <span style="color: #0c8932;width: 25px;text-align: center" class="inline-flex">{{ hours }}:</span><span class="inline-flex" style="color: #0c8932;width: 25px;text-align: center">{{ minutes }}:</span><span class="inline-flex" style="color: #0c8932;width: 25px;text-align: center">{{ seconds }}</span>
              </div>
            </div>



          </div>
          <a-pagination  @change="onChange" v-model:pageSize="pageSize" v-model:current="current" :total="totalNft" />
<!--          show-less-items-->
        </div>
<!--        <div class="box2 mb-3 max-width w-11/12">-->
<!--          <div class="flex text-white align-items-center mb-3">-->
<!--            <div class="flex-auto w-32 text-center">Name</div>-->
<!--            <div class="flex-auto w-32 text-center">SR</div>-->
<!--            <div class="flex-auto w-32 text-center">SR</div>-->
<!--            <div class="flex-auto w-32 text-center">19/20</div>-->
<!--            <div class="flex-auto w-64 text-center">-->
<!--              <button class="rounded-md text-white bg-[#67E1B8]  font-medium  text-sm px-5 py-2.5 text-center ">-->
<!--                From G 500-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="flex text-white align-items-center mb-3">-->
<!--            <div class="flex-auto w-32 text-center">Name</div>-->
<!--            <div class="flex-auto w-32 text-center">SR</div>-->
<!--            <div class="flex-auto w-32 text-center">SR</div>-->
<!--            <div class="flex-auto w-32 text-center">19/20</div>-->
<!--            <div class="flex-auto w-64 text-center">-->
<!--              <button class="rounded-md text-white bg-[#67E1B8]  font-medium  text-sm px-5 py-2.5 text-center ">-->
<!--                From G 500-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div id="history" class="history-table max-width w-11/12 p-3" style="margin:20px  auto;box-shadow: 0 10px #05060e;background: #171628;border-radius: 12px;border: 1px solid #32333f">
<!--          <div class="d-flex pb-3" style="color: #fff;justify-content: space-between;border-radius: 8px 8px 0 0px;align-items: center">-->
<!--            <div class="ml-3">-->
<!--              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M0.249995 1.61C2.26999 4.2 5.99999 9 5.99999 9V15C5.99999 15.55 6.44999 16 6.99999 16H9C9.55 16 10 15.55 10 15V9C10 9 13.72 4.2 15.74 1.61C15.8546 1.46237 15.9255 1.28553 15.9445 1.09961C15.9635 0.913684 15.9299 0.726154 15.8476 0.558371C15.7653 0.390588 15.6375 0.249293 15.4788 0.150577C15.3201 0.0518598 15.1369 -0.000312035 14.95 1.40404e-06H1.03999C0.209995 1.40404e-06 -0.260005 0.950002 0.249995 1.61Z" fill="white"/>-->
<!--              </svg>-->

<!--            </div>-->
<!--            <div style="font-size: 16px;font-family: Helvetica;font-weight: bold">History</div>-->
<!--            <div class="d-flex">-->
<!--              <div style="padding: 5px 10px;background: #67E1B8;border-radius: 5px;margin-top: 5px;margin-right: 5px">All Orders</div>-->
<!--              <div style="padding: 5px 10px;background: #5C5D8E;border-radius: 5px;margin-top: 5px;margin-right: 5px">My Orders</div>-->
<!--            </div>-->
<!--          </div>-->
          <a-table :scroll="{x:true}"  :pagination="pagination"  :columns="columns" :data-source="history_list">
<!--            <template #headerCell="{ column }">-->
<!--              <template v-if="column.key === 'moga'">-->
<!--        <span >-->
<!--          <smile-outlined />-->
<!--          Name-->
<!--        </span>-->
<!--              </template>-->
<!--            </template>-->

            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'moga'">
                <div class="flex">
                  <img width="60" :src="record.nft.logo">
                  <div class="flex ml-2 justify-content-center " style="flex-direction: column">
                    <a  class="text-xl  JungleAdventurer" style="color: yellow">
                      {{ record.nft.name }}
                    </a>
                    <div class="roboto" style="color: #a7a7ab">
                      {{ record.nft.rarity }}
                    </div>
                  </div>
                </div>

              </template>
              <template v-if="column.key === 'time'">
                <a style="color: #a7a7ab">
                  {{ endDayFormat(record.timestamp) }}
                </a>
              </template>
              <template v-if="column.key === 'price'">
                <a style="color: #a7a7ab">
                  {{ toFormatEthers(record.nft.price) }}&nbsp;GCoin
                </a>
              </template>
              <!--          <template v-else-if="column.key === 'tags'">-->
              <!--        <span >-->
              <!--          <a-tag-->
              <!--              v-for="tag in record.tags"-->
              <!--              :key="tag"-->
              <!--              :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'"-->
              <!--          >-->
              <!--            {{ tag.toUpperCase() }}-->
              <!--          </a-tag>-->
              <!--        </span>-->
              <!--          </template>-->
              <template v-else-if="column.key === 'buyer'">
                <a style="color: #a7a7ab;">
                  {{ record.toAccount }}
                </a>
              </template>
              <template v-else-if="column.key === 'tx'">
        <span>
          <a target="_blank" :href="'https://gonscan.com/tx/'+record.transaction" style="color: #a7a7ab;" class="ant-dropdown-link flex align-items-center">
            <span>{{ record.transaction }}</span>
            <svg width="14" height="14" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.5 9.5C1.225 9.5 0.9895 9.40217 0.7935 9.2065C0.597833 9.0105 0.5 8.775 0.5 8.5V1.5C0.5 1.225 0.597833 0.9895 0.7935 0.7935C0.9895 0.597833 1.225 0.5 1.5 0.5H5V1.5H1.5V8.5H8.5V5H9.5V8.5C9.5 8.775 9.40217 9.0105 9.2065 9.2065C9.0105 9.40217 8.775 9.5 8.5 9.5H1.5ZM3.85 6.85L3.15 6.15L7.8 1.5H6V0.5H9.5V4H8.5V2.2L3.85 6.85Z" fill="#5b4d75"/>
</svg>

          </a>
        </span>
              </template>
            </template>
          </a-table>
        </div>
      </a-tab-pane>
<!--      <a-tab-pane key="3" tab="Album">-->
<!--        <a-result class="roboto" style="color: #fff" title="Coming Soon">-->
<!--          <template #icon>-->
<!--            <img width="200" style="margin: auto" src="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original">-->
<!--          </template>-->
<!--          <template #extra>-->
<!--          </template>-->
<!--        </a-result>-->
<!--      </a-tab-pane>-->
    </a-tabs>

  </div>

  <a-drawer v-if="isMobile==true"
      :width="500"
            title={null}
      :placement="placement"
      :visible="visible"
      @close="onClose"
      :content-wrapper-style="{backgroundColor:'transparent',minHeight:'600px'}"
      body-style="padding:0;border:none;"
      :header-style="{display:'none'}"

  >
    <div>
      <img class="w-full"  v-if="typ==1" src="../../assets/web/poster/moga_manual_anubis_mobile.png" >
      <img class="w-full" v-else-if="typ==2" src="../../assets/web/poster/moga_manual_albion_mobile.png" >
      <img class="w-full"  v-else-if="typ==3" src="../../assets/web/poster/Moga_manual_blackgold_mobile.png" >
    </div>
  </a-drawer>
<!--  <a-modal v-else :visible="visible" :header-style="{display:'none'}">-->
<!--    <img  src="../../assets/web/poster/moga_manual_blackgold_web.png">-->
<!--  </a-modal>-->

  <a-modal  class="buyggm-modal" :closable="false" v-else v-model:visible="visible" :header-style="{display:'none'}" :footer="null" style="width: 1200px;padding-bottom: 0;background: #0A0048;overflow: hidden;border-radius: 12px" dialog-style="background: #0A0048"  body-style="background: #0A0048;padding:0;">
    <img v-if="typ==1" src="../../assets/web/poster/moga-beer.png">
    <img v-else-if="typ==2" src="../../assets/web/poster/moga_manual_blackgold_web.png" >
    <img v-else-if="typ==3" src="../../assets/web/poster/moga-wolf.png" >
  </a-modal>


  <a-modal   :closable="false"  v-model:visible="buyokvisible" :header-style="{display:'none'}" :footer="null" style="padding-bottom: 0;background: #5B4973;overflow: hidden;border-radius: 12px" dialog-style="background: #5B4973"  body-style="background: #5B4973;padding:0;">
<div class="flex flex-column align-items-center justify-content-center ">
  <div class="text-center text-white text-xl JungleAdventurer p-2 flex align-items-center" style="width: 100% ;color: #443862;border-bottom: 1px solid #9185a1;background: rgb(44,38,62);">

    <div class="flex-1 text-xl" style="color: rgb(156,131,199)">successful purchase</div>
    <img class="cursor-pointer" @click="buyokvisible=false" width="35" src="../../assets/web/getmoga/closebutton.png">
  </div>
  <div class="p-2 flex flex-column items-center justify-center">
    <div class="avatar" style="width: 140px;
        height: 140px;
        margin: 20px auto;
        background: #D9D9D9;
        box-shadow: 0 0 4px #CDFFF7, 0 0 20px #16D5FF;
        border-radius: 20px;overflow: hidden"><img src="../../assets/web/avatar-game.png" alt="" class="avatar" /></div>
    <a @click="buyokvisible=false" class="cursor-pointer text-white text-xl " href="#history">View In History</a>
  </div>
</div>

  </a-modal>


  <a-modal  class="buymoga-modal" :closable="false"  v-model:visible="buymogaVisible" :header-style="{display:'none'}"  :footer="null" style="border: 1px solid #9185a1;padding-bottom: 0;background: transparent;overflow: hidden;border-radius: 16px;box-shadow: 0 10px #342942" dialog-style="background: transparent"  body-style="background: #1d1928;padding:0;">
<!--    <div class="text-center text-white text-2xl JungleAdventurer p-2.5 flex " style="color: #443862;border-bottom: 1px solid #9185a1;background: rgb(44,38,62);">-->
<!--      <div class="flex-1" style="color: rgb(156,131,199)">buymoga</div>-->
<!--      <img class="cursor-pointer" @click="buymogaVisible=false" width="35" src="../../assets/web/getmoga/closebutton.png">-->
<!--    </div>-->

    <div style="width: 100%;height: 100%;background: #5b4973;border-radius: 8px 8px 0 0;" >
      <div class="text-center text-white text-xl JungleAdventurer p-2 flex align-items-center" style="color: #443862;border-bottom: 1px solid #9185a1;background: rgb(44,38,62);">

        <div class="flex-1 text-xl" style="color: rgb(156,131,199)">{{ pickitem.rarity}}</div>
        <img class="cursor-pointer" @click="buymogaVisible=false" width="35" src="../../assets/web/getmoga/closebutton.png">
      </div>
<!--      <div class="text-center text-white text-2xl JungleAdventurer p-2.5">{{ pickitem.rarity}}</div>-->
      <div class="mt-2">
        <img width="110" style="margin: auto"  :src="pickitem.image != undefined ? pickitem.image : DEFAULT_IMG">
      </div>
      <div class="text-center text-xl roboto" style="color: #1ADB66">{{ toFormatEthers(pickitem.price) }}&nbsp;GCOIN</div>
      <div class="text-center text-white mt-2 roboto">Payment Method</div>
      <div class="flex-column justify-content-center">
        <label class="payway w-11/12 form-check-label cursor-pointer" style="margin-top:5px"
               for="flexRadioDefault1">
          <div class="payicon flex justify-content-center align-items-center" style="flex-direction: row">
            <img class="m-4" src="../../assets/GCOIN.png">
            <div class="text-white">Gcoin</div>
          </div>

          <div class="c_radio">
            <input v-model="paytype" value="1" type="radio" name="payway"
                   id="flexRadioDefault1">
            <img width="82" src="../../assets/web/getmoga/Groupcheck.png">
<!--            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"-->
<!--                 xmlns="http://www.w3.org/2000/svg">-->
<!--              <path-->
<!--                  d="M8.6 11.8L6.425 9.625C6.24167 9.44167 6.01667 9.35 5.75 9.35C5.48333 9.35 5.25 9.45 5.05 9.65C4.86667 9.83333 4.775 10.0667 4.775 10.35C4.775 10.6333 4.86667 10.8667 5.05 11.05L7.9 13.9C8.08333 14.0833 8.31667 14.175 8.6 14.175C8.88333 14.175 9.11667 14.0833 9.3 13.9L14.975 8.225C15.1583 8.04167 15.25 7.81667 15.25 7.55C15.25 7.28333 15.15 7.05 14.95 6.85C14.7667 6.66667 14.5333 6.575 14.25 6.575C13.9667 6.575 13.7333 6.66667 13.55 6.85L8.6 11.8ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20Z"-->
<!--                  fill="#38C7F3" />-->
<!--            </svg>-->
          </div>
        </label>

      </div>
      <div class="text-center text-white mt-2 roboto">Current Account</div>
      <div class="account-info flex w-11/12  align-items-center " style="height: 90px;margin: auto;background: linear-gradient(#9c83c7 65%, #41385a 35%);border-radius: 16px 8px 8px 8px">
        <div class="avatar mr-2 ml-2">
          <img width="80" src="../../assets/web/moga_icon_wallet.png">
        </div>
        <div>
          <div class="email text-xl text-white JungleAdventurer">{{ email }}</div>
          <div class="id text-white roboto" style="color: #2c253d">Id:{{ accountid }}</div>
          <div class="yue text-white roboto mt-1 flex align-items-center w-full justify-around">Available  Balance：<div class="roboto" style="color: #645183 ">
            {{ gcoin }}</div></div>
        </div>
      </div>
      <div class="op-box flex mt-2">
        <div class="back mr-4 cursor-pointer" @click="buymogaVisible=false" >
          <img width="312" src="../../assets/web/getmoga/back.png">
        </div>
        <div class="confirm cursor-pointer" @click="handbuy">
          <img width="513" src="../../assets/web/getmoga/purchase.png">
        </div>
      </div>
    </div>

  </a-modal>
</template>
<!--endDayFormat-->
<script>
import { SmileOutlined, DownOutlined } from '@ant-design/icons-vue';
import {defineComponent, getCurrentInstance, ref} from 'vue';
import {isMobile, getPlacement, getTimestamp, getDeadline, getHash} from "@/utils";
import {
  getActionBodyBuyNFTBWO,
  sendActionBuyNFTBWO,
  DEFAULT_IMG
} from "@/constants";
import { apiBridgev2 } from "@/api/bridge";
import { queryBath,querymogaNFTs,queryHistory } from "@/api";
import { toFormatEthers } from "@/wallet/utils";
import {accountStore} from "@/store";
import {storeToRefs} from "pinia";
import { endDayFormat } from "@/utils";
import {message} from "ant-design-vue";


const columns = [{
  title: 'Moga',
  dataIndex: 'moga',
  key: 'moga',
}, {
  title: 'Time',
  dataIndex: 'time',
  key: 'time',
}, {
  title: 'Price',
  dataIndex: 'price',
  key: 'price',
}, {
  title: 'Buyer',
  key: 'buyer',
  dataIndex: 'buyer',
}, {
  title: 'Tx',
  key: 'tx',
}];
const data = [{
  key: '1',
  moga: 'John Brown',
  time: 32,
  price: 'New York No. 1 Lake Park',
  buyer:'11'
}, {
  key: '2',
  moga: 'John Brown',
  time: 32,
  price: 'New York No. 1 Lake Park',
  buyer:'11'
},{
  key: '3',
  moga: 'John Brown',
  time: 32,
  price: 'New York No. 1 Lake Park',
  buyer:'11'
}];

export default defineComponent({
  components: {
    SmileOutlined,
    DownOutlined,
  },

  setup() {
    const { getAccountId } = storeToRefs(accountStore());
    const { proxy } = getCurrentInstance()
    const placement = ref('bottom');
    const visible = ref(false);
    const buymogaVisible = ref(false);
    const buyokvisible = ref(false);
    const typ = ref(1);
    const paytype = ref(1);

    let gcoin=ref(0)
    const gcoinGgm= async () => {

      const t1 = new proxy.$accert.Token(proxy.$p, 'GCoin');
      gcoin.value = await t1.balanceOf(getAccountId.value||sessionStorage.getItem('tid'))||0;
      console.log(gcoin.value,213)
    }
    gcoinGgm()

    let totalNft=ref(0)
    let totalSold=ref(0)
    let totalOnSale=ref(0)
    let totalAction=ref(0)
    queryBath().then((res)=>{
      totalNft.value= res.data.batches[0].totalNft
      totalSold.value=res.data.batches[0].totalSold
      totalOnSale.value=res.data.batches[0].totalOnSale
      totalAction.value=res.data.batches[0].totalAction
      console.log(totalAction.value,123)
    })
    let nft_list = ref([]);
    // querymogaNFTs(1000,0).then((res)=>{
    //   nft_list.value=res.data.nfts
    //   this.targetDate = new Date(res.data.nfts[0].batch.endTimestamp)
    //   this.startCountdown();
    // })
    let history_list = ref();
    queryHistory(1000,0).then((res)=>{
      history_list.value=res.data.actions
      console.log(res,777)
    })

    const showDrawer = (v) => {
      visible.value = true;
      typ.value=v;
    };
    const onClose = () => {
      visible.value = false;
    };

    let pickitem=ref();
    let g=proxy.$g;
    const buymodal=(item)=>{
      if(item.isSold==true){
        return;
      }
      if(!sessionStorage.getItem('tid')){
        g.login();
        return
      }

      buymogaVisible.value=true
      pickitem.value=item
    }
    let email=ref()
    let accountid=ref()
   const getinfo= async () => {
     let user;
     try {
       user = await g.userInfo();
       email.value=user.email
       accountid.value=(user.accounts)[0].id
     } catch (e) {
       //err = e;
       if (e.code === 1012) {
         sessionStorage.removeItem('tid')
         location.reload()
         console.error('Token expired, %o', e);
       } else {
         console.error('userInfo error, %o', e);
       }
     }

    }
    getinfo()


    const accountActions=JSON.parse(localStorage.getItem('account'))
    const buyer=sessionStorage.getItem('cloud_wallet')
    const deadline = getDeadline();

    const handbuy = () => {

      const data = ref(getActionBodyBuyNFTBWO);
      data.value.id = getTimestamp();
      const params = [
        {
          buyer: buyer,
          tokenId: pickitem.value.tokenId,
          deadline: deadline,
        },
      ];
      data.value.params = params;
      apiBridgev2(data.value).then((res) => {
        eth_signTypedData(res);
      })
    }

    const eth_signTypedData = async (typedData) => {
      const fromAccount = new proxy.$accert.Account(proxy.$p,sessionStorage.getItem('tid')*1);

      // const t1 = new proxy.$accert.Token(proxy.$p, 'Gcoin');
      const t2 = new proxy.$accert.Token(proxy.$p, 'MOGA');
      // const provider = new Provider("https://saas.gon.com/SDK/moga/main")
      // const account = new Account(provider, 1001)
      // account.sendNFT(sessionStorage.getItem('tid')*1, new proxy.$accert.NFT(t2, '3120489452006932504'))
      console.log(1111111111111111)
      fromAccount.wallet = g; // 设置wallet提供签名功能
      // const amount = '1';
      // const mogaid='3120489452006932504'
      const signature = await g.sign(typedData.result.typedData);
      console.log(signature,21354)
      try {
        // const nft=new proxy.$accert.NFT(t2, mogaid)
        // const result = await fromAccount.sendNFT(sessionStorage.getItem('tid')*1,nft, mogaid);
        // const result = await fromAccount.sendToken(sessionStorage.getItem('tid')*1, t1, amount);

        const data = ref(sendActionBuyNFTBWO);
        data.value.id = getTimestamp();
        // const hash = getHash(typedData);
        const params = [
          {
            buyer: buyer,
            tokenId: pickitem.value.tokenId,
            deadline: deadline,
            hash:typedData.result.hash,
            signature: signature
          },
        ];
        data.value.params = params;
        apiBridgev2(data.value).then((res) => {
          if(res.error){
            message.error(res.error.message);
            return
          }
          // message.success('successful')
          buyokvisible.value=true
          buymogaVisible.value=false
          pickitem.value.isSold=true
        })
      } catch(e){
      if (e instanceof proxy.$accert.ResponseError) {
        if (e.status === 0 && e.code === 1) {
          console.log('User cancelled, %o', e);
        } else {
          console.error('Response error, %o', e);
        }
      } else {
        console.error('Error, %o', e);
      }
    }

      // const connector = new WalletConnect({
      //   bridge: "http://8.222.198.96:7777", // Required
      // });
      //
      // const signTypedData = {
      //   jsonrpc: typedData.jsonrpc,
      //   id: typedData.id,
      //   types: typedData.result.typedData.types,
      //   primaryType: typedData.result.typedData.primaryType,
      //   domain: typedData.result.typedData.domain,
      //   message: typedData.result.typedData.message,
      // };
      //
      // const msgParams = [accountActions.authes[0].address, JSON.stringify(signTypedData)];
      // console.log(msgParams,126237)
      //     connector
      //         .signTypedData(msgParams)
      //         .then((signature) => {
      //           console.log(signature,6890)
      //         })
    }
    return {
      data,
      columns,
      activeKey: ref('2'),
      placement,
      visible,
      showDrawer,
      typ,
      onClose,
      isMobile,
      buymogaVisible,
      buyokvisible,
      paytype,
      totalNft,
      totalSold,
      totalOnSale,
      totalAction,
      history_list,
      nft_list,
      toFormatEthers,
      buymodal,
      pickitem,
      getAccountId,
      gcoin,
      handbuy,
      email,
      accountid,
      DEFAULT_IMG,
      endDayFormat,

    };
  },
  data() {
    return {
      countdown: null,
      targetDate: new Date('2023-08-30'), // 倒计时的目标日期
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      // nft_list:[],
      timeDifference:null,
      pageSize: 12,
      current:1,
      pagination: {
        total: 0,
        pageSize: 10,//每页中显示10条数据
        showSizeChanger: false,
        pageSizeOptions: ["10", "20", "50", "100"],//每页中显示的数据
        // showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
      },
    };
  },
  mounted() {
    // this.startCountdown();
    console.log(this.totalAction,878990)
    this.getList(0)
  },
  methods: {
    onChange(e){
      this.getList((e-1)*12)
    },
    getList(e){
      querymogaNFTs(12,e).then((res)=>{
        if (res.data.nfts.length<1){
          return
        }
        this.nft_list=res.data.nfts
        this.targetDate = res.data.nfts[0].batch.endTimestamp
        // new Date(res.data.nfts[0].batch.endTimestamp)
        this.startCountdown();
      })
    },
    startCountdown() {
      this.countdown = setInterval(() => {
        const now = new Date();
        // const timeDifference = this.targetDate.getTime() - now.getTime();
        const timeDifference = this.targetDate*1000 - now.getTime();
        this.timeDifference=timeDifference

        this.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24)).toString().padStart(2, '0');
        this.hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, '0');
        this.minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
        this.seconds = Math.floor((timeDifference % (1000 * 60)) / 1000).toString().padStart(2, '0');

        if (timeDifference < 0) {
          if (this.countdown) {
            clearInterval(this.countdown);
          }
          this.days = 0;
          this.hours = 0;
          this.minutes = 0;
          this.seconds = 0;
        }
      }, 1000);
    }
    // startCountdown() {
    //   this.countdown = setInterval(() => {
    //     const now = new Date().getTime();
    //     const timeDifference = this.targetDate*1000 - now;
    //
    //     if (timeDifference > 0) {
    //       this.minutes = Math.floor(timeDifference / (1000 * 60));
    //     } else {
    //       clearInterval(this.countdown);
    //       this.minutes = 0;
    //     }
    //   }, 1000);
    // }
  },
  beforeUnmount() {
    if (this.countdown) {
      clearInterval(this.countdown);
    }
  }
});
</script>

<style scoped lang="less">

.d-flex{display: flex}
.flex-column{flex-direction: column}
.align-items-center{align-items: center}
.justify-content-start{justify-content: start}
.justify-content-center{justify-content: center}
.mt-4{margin-top: 1.5rem}
.w-100{width: 100%}
.cursor-pointer{cursor: pointer}
::v-deep .ant-table{background: transparent;color: #fff;
  border-radius: 10px; }
::v-deep .ant-table-thead > tr > th{background: #1B203E;color: #fff;border: none;font-family: "Jungle Adventurer"}
::v-deep .ant-table-tbody > tr > td{border: none}
::v-deep .ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;

}
::v-deep .ant-table-cell{
  border-bottom: 10px solid #161626 !important;
}
::v-deep .ant-table-row{border-radius: 12px !important;border: 2px solid #ffffff;background-color: #0f0f1a ;
}


::v-deep .ant-table-tbody > tr >td.ant-table-cell-row-hover{
  background: none !important;
}
::v-deep .ant-pagination{
  display: table;background: #1B203E;padding: 10px 20px;border-radius: 10px;color: #fff;margin: 16px auto;
}
::v-deep .ant-pagination-item{
  background-color: transparent;border: none;
}
::v-deep .ant-pagination-item-link{
  background: none;border: none;color: #fff;
}
::v-deep .ant-pagination-item-active{
  background: #5C5D8E;border: none;
}
::v-deep .ant-pagination-item-active a{
  color: #0A0048;
}
::v-deep .ant-pagination-item a{color: #fff}

::v-deep .ant-result-title{color: #fff}
::v-deep .ant-tabs-nav-list{background-color: #323447;border-radius: 12px 12px 0 0;margin: auto;text-align: center;overflow: hidden;
border: 1px solid #3f4154}
::v-deep .ant-tabs-tab{margin: 0;padding: 10px 20px;font-family: "Jungle Adventurer";color: #797a87;
}
::v-deep .ant-tabs-ink-bar{
  background: transparent;
}
::v-deep .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #ffffff;

  text-shadow: 0 0 5px #ffffff	//设置发光效果

}
::v-deep .ant-tabs-tab-active{
  background-color: #62517d;
  color: #fff;
  border-radius: 6px 6px 0 0;
  box-shadow: 0 0 8px #000;

}
body {
  /* background: #F2F1F9; */
}
.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 200px);
  color: #121037;
}
.max-width {
  max-width: 1200px;
  margin: 0 auto;
}
.claim-chest{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 100%;
  padding: 7px 8px;
  /* identical to box height, or 10px */

  display: flex;
  align-items: center;
  text-align: center;

  /* white */

  color: #FFFFFF;
  background: #5C5D8E;
  border-radius: 5px;margin-right: 5px;margin-left: 5px;
}
.genesis-moga{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 100%;
  padding: 7px 8px;
  /* identical to box height, or 10px */

  display: flex;
  align-items: center;
  text-align: center;

  /* white */

  color: #FFFFFF;
  background: #67E1B8;
  border-radius: 5px;
}
.t-title{
  font-family: 'Jungle Adventurer';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  /* or 20px */

  display: flex;
  align-items: center;
  text-align: center;justify-content: center;

  /* white */

  color: #FFFFFF;

  /* light glow */

  text-shadow: 0px 0px 4px #16D5FF;
}
.getmoga-nav{
  justify-content: space-around;
.nav-item{
  width: 24%;height: 64px;
  background: linear-gradient(63.76deg, #0A0C18 28.59%, #252C56 83.49%);
  border-radius: 10px;align-items: center;justify-content: center;text-align: center;
  filter: drop-shadow(2px 2px 4px rgba(10, 0, 72, 0.2));border: 1px solid rgba(23,66,89,1);
}
.num{
  font-family: 'Jungle Adventurer';
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
.txt{
  font-family: 'robotoslab';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: #6e548a;
}
}
.ends{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #FFFFFF;
  filter: drop-shadow(0px 0px 4px #CDFFF7) drop-shadow(0px 0px 20px #16D5FF)
}
//盲盒
.cards-ul{display: flex;flex-wrap: wrap;font-size: 16px;font-family: roboto;
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  -webkit-box-pack: center;
  justify-content: center;
}
//end
.buy-module{background-color: #161627;border-radius: 12px;box-shadow: 0  8px #05060e;border: 1px solid #32333f}
.buy-ul{display: flex;flex-wrap: wrap;font-size: 16px;font-family: roboto;
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  -webkit-box-pack: center;
  justify-content: center;
}
.buy-li{
  width: 200.5px;
  height: 436.5px;
  background-image: url("../../assets/web/getmoga/buycard01.png");
  background-size: cover;
  margin: 20px;
}

.three-ul{display: flex;flex-wrap: wrap;font-size: 16px;font-family: roboto;
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  -webkit-box-pack: center;
  justify-content: center;
}
.three-li{
  width: 309px;
  height: 362px;
  background-size: cover;
  margin: 20px;
}
.three-m-li{
  width:100%;
  height: 362px;
  background-size: cover;
  margin: 20px;
}
.hovered{display: none}
.three-li:hover{
  .hovered{display: flex}
}
.three-li:nth-child(1),.three-m-li:nth-child(1){
  background-image: url("../../assets/salecard002.png");
}
.three-li:nth-child(2),.three-m-li:nth-child(2){
  background-image: url("../../assets/salecard001.png");
}
.three-li:nth-child(3),.three-m-li:nth-child(3){
  background-image: url("../../assets/salecard03.png");
}
.payway{
  height: 66px;
  background: #2a243b;
  border-radius: 5px;
  margin: 5px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
}
.c_radio{margin-right: 10px;}
.c_radio svg{display: none;}
.c_radio input{display: none;}
.c_radio input:checked + svg {
  display: inline-block;
}
@media (max-width: 768px) {
.getmoga-bg{
  //background: url("../../assets/web/getmoga/getmogabg.png") no-repeat;background-size: 100% 100%;
}
}
</style>
