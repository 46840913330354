<template>

  <div class="main  flex flex-col items-center  mx-auto mt-2 pb-5" >
    <div class="w-96">
      <TabbarLayout></TabbarLayout>
    </div>
        <div class="w-96 pinzi grid grid-cols-7 gap-2 mt-4">
          <div class="pinzi-item bg-local" @click="handtype(1)" :class="typ_active==1?'active':''">
            <img  src="../../assets/app/wallet/icon_elment_all.png">
          </div>
          <div class="pinzi-item" @click="handtype(2)" :class="typ_active==2?'active':''">
            <img  src="../../assets/app/wallet/icon_element_fire.png">
          </div>
          <div class="pinzi-item" @click="handtype(3)" :class="typ_active==3?'active':''">
            <img  src="../../assets/app/wallet/icon_element_dark.png">
          </div>
          <div class="pinzi-item" @click="handtype(4)" :class="typ_active==4?'active':''">
            <img src="../../assets/app/wallet/icon_element_air.png">
          </div>
          <div class="pinzi-item" @click="handtype(5)" :class="typ_active==5?'active':''">
            <img  src="../../assets/app/wallet/icon_element_light.png">
          </div>
          <div class="pinzi-item" @click="handtype(6)" :class="typ_active==6?'active':''">
            <img src="../../assets/app/wallet/icon_element_dark.png">
          </div>
          <div class="pinzi-item" @click="handtype(7)" :class="typ_active==7?'active':''">
            <img  src="../../assets/app/wallet/icon_element_anima.png">
          </div>
        </div>
        <div class="JungleAdventurer text-2xl mt-2" style="color: #fbd940">
          <span class="font-bold" style="color: #745f75">·</span>{{ typ }}<span class="font-bold" style="color: #745f75">·</span>
        </div>


        <div class="w-96 grid grid-cols-4 gap-2 mt-4  ">
          <div class="w-20 itembg">
            <img src="../../assets/app/wallet/test001.png">
          </div>
          <div class="w-20 itembg">
            <img src="../../assets/app/wallet/test001.png">
          </div>
          <div class="w-20 itembg">
            <img src="../../assets/app/wallet/test001.png">
          </div>
          <div class="w-20 itembg">
            <img src="../../assets/app/wallet/test001.png">
          </div>
          <div class="w-20 itembg">
            <img src="../../assets/app/wallet/test001.png">
          </div>
          <div class="w-20 itembg">
            <img src="../../assets/app/wallet/test001.png">
          </div>
          <div class="w-20 itembg">
            <img src="../../assets/app/wallet/test001.png">
          </div>
        </div>

  </div>
  <a-modal   :closable="false"  v-model:visible="ggmvisible" :header-style="{display:'none'}" :footer="null" style="padding-bottom: 0;background: #5B4973;overflow: hidden;border-radius: 12px" dialog-style="background: #5B4973"  body-style="background: #5B4973;padding:0;">
    <div class="flex flex-column align-items-center justify-content-center ">
      <div class="text-center text-white text-xl JungleAdventurer p-2 flex align-items-center" style="width: 100% ;color: #443862;border-bottom: 1px solid #9185a1;background: rgb(44,38,62);">
        <div class="flex-1 text-xl" style="color: rgb(156,131,199)">SEND</div>
        <img class="cursor-pointer" @click="buyokvisible=false" width="35" src="../../assets/web/getmoga/closebutton.png">
      </div>
      <div class="p-2 flex flex-column items-center justify-center">
<!--        <div class="avatar" style="width: 140px;-->
<!--        height: 140px;-->
<!--        margin: 20px auto;-->
<!--        background: #D9D9D9;-->
<!--        box-shadow: 0 0 4px #CDFFF7, 0 0 20px #16D5FF;-->
<!--        border-radius: 20px;overflow: hidden"><img src="../../assets/web/avatar-game.png" alt="" class="avatar" /></div>-->
<!--        <a @click="buyokvisible=false" class="cursor-pointer text-white text-xl " href="#history">View In History</a>-->
      </div>
    </div>
  </a-modal>
</template>

<script>
import TabbarLayout from "@/layouts/module/Tabbar.vue";
import WalletConnector from "@/wallet/components/WalletConnector";
import {selectDictLabel} from "@/utils/custom"
import {ref} from 'vue';
const dictoptions=[
  {
    value: 1,
    label: 'rate'
  },
  {
    value: 2,
    label: 'rate'
  },
  {
    value: 3,
    label: 'rate'
  },
  {
    value: 4,
    label: 'rate'
  },
  {
    value: 5,
    label: 'rate'
  },
  {
    value: 6,
    label: 'rate'
  },
  {
    value: 7,
    label: 'rate'
  },
]
export default {
  name: "wallet",
  components: {
    TabbarLayout,
  },
  setup(){
    let typ_active=ref(1)
    let pagination={
      total: 0,
      pageSize: 10,//每页中显示10条数据
      showSizeChanger: false,
      pageSizeOptions: ["10", "20", "50", "100"],//每页中显示的数据
      // showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
    }
    let typ=ref('rate')
    const handtype = (e)=>{
      typ_active.value=e
      typ.value=selectDictLabel(dictoptions, e);
    }
    return{
      typ_active,
      handtype,
      pagination: pagination,
      typ,

    }
  },
}
</script>

<style scoped>
.pinzi{
  background: linear-gradient(0deg, #5B4973 0%, rgba(91, 73, 115, 0.00) 100%);
  border-radius: 12px;
  border: 2px solid #5B4973;
  padding: 5px 10px;
  box-shadow: 0px  3px  #40314c;
}
.pinzi-item{
  background-image: url("../../assets/app/wallet/pinzi.png");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pinzi-item.active{
  box-shadow: 0 0 0 .5px #38393e,0 0 0 1px #ae90d6,0 0 0 1.5px #675381;
  border-radius: 50%;
  /*background-image: url("../../assets/app/wallet/pinziactive.png");*/
  /*background-size: cover;*/
  /*background-position: center;*/
}
.pinzi-item img{
  height: 70%;
}
.itembg{
  background-image: url("../../assets/app/wallet/itembg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /*aspect-ratio: 324 / 392;*/
  aspect-ratio:265/333;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
