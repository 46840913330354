import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../assets/GCOIN.png'
import _imports_1 from '../../assets/ggm_icon.png'
import _imports_2 from '../../assets/buy.png'
import _imports_3 from '../../assets/sell.png'
import _imports_4 from '../../assets/open.png'


const _withScopeId = n => (_pushScopeId("data-v-7ad1770e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = { class: "flex space-x-4" }
const _hoisted_3 = { class: "mt-2" }
const _hoisted_4 = {
  key: 0,
  src: _imports_0,
  class: "w-14"
}
const _hoisted_5 = {
  key: 1,
  src: _imports_1,
  class: "w-14"
}
const _hoisted_6 = {
  key: 2,
  class: "h-14 w-14"
}
const _hoisted_7 = { class: "flex flex-col space-y-1" }
const _hoisted_8 = {
  key: 0,
  class: "assets-token"
}
const _hoisted_9 = {
  key: 1,
  class: "assets-nft"
}
const _hoisted_10 = {
  key: 2,
  class: "green-title"
}
const _hoisted_11 = {
  key: 3,
  class: "green-title"
}
const _hoisted_12 = {
  key: 4,
  class: ""
}
const _hoisted_13 = { class: "flex flex-col justify-between items-end space-y-1" }
const _hoisted_14 = { class: "flex space-x-2" }
const _hoisted_15 = {
  key: 0,
  class: "font-semibold"
}
const _hoisted_16 = {
  key: 1,
  class: "font-semibold"
}
const _hoisted_17 = {
  key: 2,
  class: "font-semibold"
}
const _hoisted_18 = {
  key: 3,
  src: _imports_2,
  class: "h-2 w-2 mt-2"
}
const _hoisted_19 = {
  key: 4,
  src: _imports_3,
  class: "h-2 w-2 mt-2"
}
const _hoisted_20 = { class: "flex space-x-2" }
const _hoisted_21 = { class: "assets-time cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_card = _resolveComponent("a-card")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createBlock(_component_a_card, {
    loading: _ctx.loading,
    class: "assets-card rounded-md mt-2"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.title == _ctx.SYMBOL_GGM)
              ? (_openBlock(), _createElementBlock("img", _hoisted_4))
              : (_ctx.title == 'GGM')
                ? (_openBlock(), _createElementBlock("img", _hoisted_5))
                : _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_6, null, 512)), [
                    [_directive_lazy, _ctx.logoUrl]
                  ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            (_ctx.title == _ctx.SYMBOL_GGM)
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.title), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.title), 1)),
            (!(_ctx.title == _ctx.SYMBOL_GGM||_ctx.title =='GGM'))
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.name), 1))
              : _createCommentVNode("", true),
            (_ctx.title == _ctx.SYMBOL_GGM||_ctx.title =='GGM')
              ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.toFormatEthers(_ctx.param2)), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_12, "#" + _toDisplayString(_ctx.param2.replace(/^(.{4}).*(.{4})$/, "$1***$2")), 1))
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            (_ctx.Transaction_Type.Transfer == _ctx.transactionType && _ctx.param1 != '0')
              ? (_openBlock(), _createElementBlock("span", _hoisted_15, "Transfer"))
              : _createCommentVNode("", true),
            (_ctx.param1 === '0')
              ? (_openBlock(), _createElementBlock("span", _hoisted_16, "Mint"))
              : _createCommentVNode("", true),
            (_ctx.param1 === '324' && _ctx.isIn != 'in')
              ? (_openBlock(), _createElementBlock("span", _hoisted_17, "Burn"))
              : _createCommentVNode("", true),
            (_ctx.isIn == 'in')
              ? (_openBlock(), _createElementBlock("img", _hoisted_18))
              : (_openBlock(), _createElementBlock("img", _hoisted_19))
          ]),
          _withDirectives(_createElementVNode("span", {
            style: {"color":"#1ADB66"},
            class: "cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goAccountWallet(_ctx.param1)))
          }, [
            _createTextVNode("[Account "),
            _createElementVNode("span", null, _toDisplayString(_ctx.param1), 1),
            _createTextVNode("]")
          ], 512), [
            [_vShow, _ctx.param1 != '0']
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.endDayFormat(_ctx.day)), 1),
            _createElementVNode("img", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openUrl(_ctx.hash,_ctx.logIndex))),
              src: _imports_4,
              class: "h-3 w-3 mt-1 cursor-pointer"
            })
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}