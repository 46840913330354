import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "main flex flex-col items-center justify-center mx-auto mt-2" }
const _hoisted_2 = { class: "w-96" }
const _hoisted_3 = {
  class: "activity assets-box mt-4 w-96 bg-[#1F1927] pb-5",
  "infinite-scroll-disabled": "busy",
  "infinite-scroll-distance": "10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabbarLayout = _resolveComponent("TabbarLayout")!
  const _component_Assets = _resolveComponent("Assets")!
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TabbarLayout)
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assetActions, (item) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          key: item.id
        }, [
          _createVNode(_component_Assets, {
            "logo-url": 
            _ctx.logoUrlMapping.get(item.nft.id) != undefined
              ? _ctx.logoUrlMapping.get(item.nft.id)
              : ''
          ,
            title: item.asset.symbol,
            transactionType: _ctx.Transaction_Type.Transfer,
            day: item?.action?.timestamp,
            "is-in": item?.param3,
            "is-b-w-o": item?.isBWO,
            hash: item?.action?.transaction?.id,
            param2: item?.param2,
            logIndex: item?.action?.logIndex,
            param1: item?.param1,
            name: 
              _ctx.nameMapping.get(item.nft.id) != undefined
                ? _ctx.nameMapping.get(item.nft.id)
                : ''
            
          }, null, 8, ["logo-url", "title", "transactionType", "day", "is-in", "is-b-w-o", "hash", "param2", "logIndex", "param1", "name"])
        ])), [
          [_vShow, _ctx.assetActions?.length > 0]
        ])
      }), 128))
    ])), [
      [_directive_infinite_scroll, _ctx.loadMore]
    ])
  ]))
}