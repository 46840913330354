import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = { class: "flex justify-center space-x-2" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "flex flex-col items-center justify-center" }
const _hoisted_6 = { class: "black-title" }
const _hoisted_7 = { class: "flex space-x-2" }
const _hoisted_8 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, {
    loading: _ctx.loading,
    class: "user-card"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", {
              class: "bg-transparent mt-1",
              innerHTML: _ctx.avatar?.outerHTML.replaceAll('32', '25')
            }, null, 8, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", null, _toDisplayString('[AccountId '+ _ctx.accountId+']'), 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.shortenAddress(_ctx.address)), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _withDirectives(_createElementVNode("span", {
              class: "px-2 py-1 rounded cursor-pointer max-button",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.select && _ctx.select(...args)))
            }, "Select", 512), [
              [_vShow, _ctx.type == _ctx.User_Type.Select]
            ]),
            _withDirectives(_createElementVNode("span", {
              class: "px-2 w-8 text-center rounded cursor-pointer text-lg black-title",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clear && _ctx.clear(...args)))
            }, "x", 512), [
              [_vShow, _ctx.type == _ctx.User_Type.Close]
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}