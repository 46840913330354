
import { useRouter, useRoute } from "vue-router";
import { computed } from "vue";
export default {
  name: "tabbarItem",
  props: {
    path: String
  },
  setup(props) {
    const router = useRouter();
    const itemClick = () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      router.push(props.path!);
    };

    const route = useRoute();
    const isSelected = computed(() => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return route.path.includes(props.path!);
    });

    // eslint-disable-next-line vue/return-in-computed-property
    const selectedText = computed(() => {
      if (isSelected.value) {
        return {
          color: "white",
        };
      } else {
        return {
          color: "darkgray",
        };
      }
    });

    // eslint-disable-next-line vue/return-in-computed-property
    const selectedBackgroundColor = computed(() => {
      if (isSelected.value) {
        return {
          background: "#393961",
        };
      }
    });

    return {
      itemClick,
      isSelected,
      selectedText,
      selectedBackgroundColor,
    };
  },
};
