
import { defineComponent, ref, watch } from "vue";
import { getPlacement } from "@/utils";

export default defineComponent({
  name: "NFT Details",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    isMe: {
      type: Boolean,
      required: true,
    },
  },
  components: {},
  setup(props: any, context: { emit: (arg0: string, arg1: any) => void }) {
    const placement = ref<string>(String(getPlacement()));

    const handleClick = () => {
      context.emit("event", props.item);
    };

    const handleCrossChainClick = () => {
      context.emit("crossChainEvent", props.item);
    }

    return {
      placement,
      handleClick,
      handleCrossChainClick,
    };
  },
});
