import apolloClient from "@/utils/graphsql";
import gql from "graphql-tag";

const METAVERSE = process.env.VUE_APP_METAVERSE?.toLocaleLowerCase();
const WORLD = process.env.VUE_APP_WORLD?.toLocaleLowerCase();

// 根据AccountID获取余额
export function queryBalancesByIdAndSymbol(accountId: string) {
  const id = METAVERSE + "@" + accountId;
  const GET_BALANCE = gql`
    query balance($id: String!) {
      account(id: $id) {
        assets(where: { asset_: { symbol: "GCOIN" } }) {
          id
          asset {
            id
            symbol
          }
          amount
        }
      }
    }
  `;
  return apolloClient.query({
    query: GET_BALANCE,
    variables: {
      id,
    },
  });
}
//wang
export function myqueryBalancesByIdAndSymbol(accountId: string) {
  const id = METAVERSE + "@" + accountId;
  const GET_BALANCE = gql`
    query balance($id: String!) {
      account(id: $id) {
        assets(where: { asset_: { symbol: "GGM" } }) {
          id
          asset {
            id
            symbol
          }
          amount
        }
      }
    }
  `;
  return apolloClient.query({
    query: GET_BALANCE,
    variables: {
      id,
    },
  });
}

// 获取余额
export function queryBalance() {
  const store = accountStore();
  const { getAId } = storeToRefs(store);

  const id = METAVERSE + "@" + getAId.value;

  const GET_BALANCE = gql`
    query balance($id: String!) {
      account(id: $id) {
        assets(where: { asset_: { symbol: "GCOIN" } }) {
          id
          asset {
            id
            symbol
          }
          amount
        }
      }
    }
  `;
  return apolloClient.query({
    query: GET_BALANCE,
    variables: {
      id,
    },
  });
}

import { accountStore } from "@/store";
import { storeToRefs } from "pinia";

// 获取NFTs
export function queryNFTs(type: number) {
  const store = accountStore();
  const { getAccountId } = storeToRefs(store);

  const id = METAVERSE + "@" + getAccountId.value;
  const symbol = type == 0 ? "MOGA" : "EQPT";

  const GET_NFTs = gql`
    query NFTs($id: String!, $symbol: String!) {
      nfts(where: { owner_: { id: $id }, asset_: { symbol: $symbol } }) {
        itemId
        id
        asset {
          symbol
          id
        }
        metadatas {
          id
          key
          value
        }
      }
    }
  `;

  return apolloClient.query({
    query: GET_NFTs,
    variables: {
      id,
      symbol,
    },
  });
}

export function queryNFTsByAccountId(type: number, accountId: string) {

  const id = METAVERSE + "@" + accountId;
  const symbol = type == 0 ? "MOGA" : "EQPT";

  const GET_NFTs = gql`
    query NFTs($id: String!, $symbol: String!) {
      nfts(where: { owner_: { id: $id }, asset_: { symbol: $symbol } }) {
        tokenId
        id
        asset {
          symbol
          id
        }
        metadatas {
          id
          key
          value
        }
      }
    }
  `;

  return apolloClient.query({
    query: GET_NFTs,
    variables: {
      id,
      symbol,
    },
  });
}

export function queryNFTById(id: string) {
  const GET_NFT = gql`
    query NFTs($id: String!) {
      nfts(where: { id: $id }) {
        itemId
        id
        asset {
          symbol
        }
        metadatas {
          id
          key
          value
        }
      }
    }
  `;

  return apolloClient.query({
    query: GET_NFT,
    variables: {
      id,
    },
  });
}

import { PAGE_SIZE } from "@/constants";

// 获取AssetActions
export function queryAssetActions(page: number) {
  const store = accountStore();
  const { getAId } = storeToRefs(store);

  const id = METAVERSE + "@" + getAId.value;
  const size = PAGE_SIZE * page;

  const GET_ASSET_ACTIONS = gql`
    query AssetActions($id: String!, $size: Int!) {
      account(id: $id) {
        assetActions(orderDirection: desc, first: $size, orderBy: timeindex) {
          asset {
            id
            symbol
            decimals
          }
          nft {
            id
            metadatas {
              key
              value
            }
          }
          actionType
          isBWO
          sender
          nonce
          param1
          param2
          param3
          action {
            logIndex
            timestamp
            transaction {
              id
            }
          }
        }
      }
    }
  `;

  return apolloClient.query({
    query: GET_ASSET_ACTIONS,
    variables: {
      id,
      size,
    },
  });
}

// 获取AccountActions
export function queryAccountActions(page: number) {
  const store = accountStore();
  const { getAccountId } = storeToRefs(store);

  const id = METAVERSE + "@" + getAccountId.value;
  const size = PAGE_SIZE * page;
  // const after = PAGE_SIZE;

  const GET_ACCOUNT_ACTIONS = gql`
    query AccountActions($id: String!, $size: Int!) {
      account(id: $id) {
        accountActions(orderDirection: desc, first: $size, orderBy: nonce) {
          actionType
          isBWO
          sender
          nonce
          param1
          param2
          param3
          action {
            logIndex
            contract
            timestamp
            transaction {
              id
            }
          }
        }
      }
    }
  `;

  return apolloClient.query({
    query: GET_ACCOUNT_ACTIONS,
    variables: {
      id,
      size,
    },
  });
}

// 根据钱包地址获取账号ID
export function queryAccountID(address: String) {
  const metaverse = METAVERSE
  const GET_ACCOUNT_ID = gql`
    query AccountID($metaverse: String!, $address: String!) {
      authAddresses(
        where: {
          account_: { metaverse: $metaverse }
          address: $address
          isEnable: true
        }
      ) {
        id
        address
        isEnable
        account {
          metaverse {
            id
          }
          accountId
        }
      }
    }
  `;

  return apolloClient.query({
    query: GET_ACCOUNT_ID,
    variables: {
      metaverse,
      address,
    },
  });
}

//验证账号/地址是否授权
export function verifyAuthorization(accountId: String, address: String) {
  const id = METAVERSE + "@" + accountId;
  const VERIFY_AUTHORIZATION = gql`
    query verifyAuthorization($id: String!, $address: String!) {
      authAddresses(where: { account_: { id: $id }, address: $address }) {
        id
        address
        isEnable
        account {
          metaverse {
            id
          }
          accountId
        }
      }
    }
  `;

  return apolloClient.query({
    query: VERIFY_AUTHORIZATION,
    variables: {
      id,
      address,
    },
  });
}

//验证账号/地址是否授权
// authAddresses(
//   where: {address: "0x1c7bcd3172ed0799c16cfa9442d72ab4a3d542b3", account_: {id: "0x6db465110eeaa3fef194a860e54d1702db305409@324"}}
// ) {
//   address
//   id
//   isEnable
//   account {
//     accountId
//   }
// }

// 获取NFT属性信息
// nft(id: "0x4af043fd477cfd3688bcbfd43373bfc24994008c/1100201010400003620") {
//   id
//   # itemId
//   metadatas {
//     value
//     key
//     # isEnable
//     id
//   }
// }

// 获取账号信息
export function queryAccountInfo() {

  const store = accountStore();
  const { getAccountId } = storeToRefs(store);

  const id = METAVERSE + "@" + getAccountId.value;
  const worldId = WORLD

  const GET_ACCOUNT_INFO = gql`
    query getAccountInfo($id: String!, $worldId: String!) {
      account(id: $id) {
          accountId
          isFrozen
          
          isTrustAdmin
          trustedWorlds(
            where: {
              world_: { id: $worldId }
            }
          ) {
            id
            isTurst
          }
          authAddresses(where: {
            isEnable: true
          }) {
            id
            address
            isEnable
          }
        }
    }
  `;

  return apolloClient.query({
    query: GET_ACCOUNT_INFO,
    variables: {
      id,
      worldId,
    },
  });


  // // const id = METAVERSE + "@" + accountId;
  // return apolloClient.query({
  //   query: gql`
  //     {
  //       account(id: "0x6b6d0b13956f6cf8bd906a01c0ceef17961c80fa@324") {
  //         accountId
  //         isFrozen
  //         isTrustAdmin
  //         trustedWorlds(
  //           where: {
  //             world_: { id: "0x4a9ba3ac334d01d0a72c0b5ed1b12ddc72cb183b" }
  //           }
  //         ) {
  //           id
  //           isTurst
  //         }
  //         authAddresses {
  //           id
  //           address
  //         }
  //       }
  //     }
  //   `,
  // });
}
