import { ref } from "vue";

export function useToggleSendNFTDrawer() {
  const visibleSendNFTDrawer = ref(false);
  const showSendNFTDrawer = () => {
    visibleSendNFTDrawer.value = true;
  };
  const hideSendNFTDrawer = () => {
    visibleSendNFTDrawer.value = false;
  };
  return {
    visibleSendNFTDrawer,
    showSendNFTDrawer,
    hideSendNFTDrawer,
  };
}

export function useToggleSendTokenDrawer() {
  const visibleSendTokenDrawer = ref(false);
  const showSendTokenDrawer = () => {
    visibleSendTokenDrawer.value = true;
  };
  const hideSendTokenDrawer = () => {
    visibleSendTokenDrawer.value = false;
  };
  return {
    visibleSendTokenDrawer,
    showSendTokenDrawer,
    hideSendTokenDrawer,
  };
}
//wang
export function useToggleGgmSendTokenDrawer() {
  const visibleGgmSendTokenDrawer = ref(false);
  const showGgmSendTokenDrawer = () => {
    visibleGgmSendTokenDrawer.value = true;
  };
  const hideGgmSendTokenDrawer = () => {
    visibleGgmSendTokenDrawer.value = false;
  };
  return {
    visibleGgmSendTokenDrawer,
    showGgmSendTokenDrawer,
    hideGgmSendTokenDrawer,
  };
}


export function useTogglePurchaseGGMDrawer() {
  const visiblePurchaseGGMDrawer = ref(false);
  const showPurchaseGGMDrawer = () => {
    visiblePurchaseGGMDrawer.value = true;
  };
  const hidePurchaseGGMDrawer = () => {
    visiblePurchaseGGMDrawer.value = false;
  };
  return {
    visiblePurchaseGGMDrawer,
    showPurchaseGGMDrawer,
    hidePurchaseGGMDrawer,
  };
}

export function useToggleSellGGMDrawer() {
  const visibleSellGGMDrawer = ref(false);
  const showSellGGMDrawer = () => {
    visibleSellGGMDrawer.value = true;
  };
  const hideSellGGMDrawer = () => {
    visibleSellGGMDrawer.value = false;
  };
  return {
    visibleSellGGMDrawer,
    showSellGGMDrawer,
    hideSellGGMDrawer,
  };
}

export function useToggleSellGGMOffersDrawer() {
  const visibleSellGGMOffersDrawer = ref(false);
  const showSellGGMOffersDrawer = () => {
    visibleSellGGMOffersDrawer.value = true;
  };
  const hideSellGGMOffersDrawer = () => {
    visibleSellGGMOffersDrawer.value = false;
  };
  return {
    visibleSellGGMOffersDrawer,
    showSellGGMOffersDrawer,
    hideSellGGMOffersDrawer,
  };
}

export function useToggleExGGMOffersDrawer() {
  const visibleExGGMOffersDrawer = ref(false);
  const showExGGMOffersDrawer = () => {
    visibleExGGMOffersDrawer.value = true;
  };
  const hideExGGMOffersDrawer = () => {
    visibleExGGMOffersDrawer.value = false;
  };
  return {
    visibleExGGMOffersDrawer,
    showExGGMOffersDrawer,
    hideExGGMOffersDrawer,
  };
}

export function useToggleSendToDrawer() {
  const visibleSendToDrawer = ref(false);
  const showSendToDrawer = () => {
    visibleSendToDrawer.value = true;
  };
  const hideSendToDrawer = () => {
    visibleSendToDrawer.value = false;
  };
  return {
    visibleSendToDrawer,
    showSendToDrawer,
    hideSendToDrawer,
  };
}

export function useToggleExchangeWithDrawer() {
  const visibleExchangeWithDrawer = ref(false);
  const showExchangeWithDrawer = () => {
    visibleExchangeWithDrawer.value = true;
  };
  const hideExchangeWithDrawer = () => {
    visibleExchangeWithDrawer.value = false;
  };
  return {
    visibleExchangeWithDrawer,
    showExchangeWithDrawer,
    hideExchangeWithDrawer,
  };
}

export function useToggleExchangeDrawer() {
  const visibleExchangeDrawer = ref(false);
  const showExchangeDrawer = () => {
    visibleExchangeDrawer.value = true;
  };
  const hideExchangeDrawer = () => {
    visibleExchangeDrawer.value = false;
  };
  return {
    visibleExchangeDrawer,
    showExchangeDrawer,
    hideExchangeDrawer,
  };
}

export function useToggleCrossChainTokenDrawer() {
  const visibleCrossChainTokenDrawer = ref(false);
  const showCrossChainTokenDrawer = () => {
    visibleCrossChainTokenDrawer.value = true;
  };
  const hideCrossChainTokenDrawer = () => {
    visibleCrossChainTokenDrawer.value = false;
  };
  return {
    visibleCrossChainTokenDrawer,
    showCrossChainTokenDrawer,
    hideCrossChainTokenDrawer,
  };
}
//wang
export function useToggleGgmCrossChainTokenDrawer() {
  const visibleGgmCrossChainTokenDrawer = ref(false);
  const showGgmCrossChainTokenDrawer = () => {
    visibleGgmCrossChainTokenDrawer.value = true;
  };
  const hideGgmCrossChainTokenDrawer = () => {
    visibleGgmCrossChainTokenDrawer.value = false;
  };
  return {
    visibleGgmCrossChainTokenDrawer,
    showGgmCrossChainTokenDrawer,
    hideGgmCrossChainTokenDrawer,
  };
}


export function useToggleCrossChainNFTDrawer() {
  const visibleCrossChainNFTDrawer = ref(false);
  const showCrossChainNFTDrawer = () => {
    visibleCrossChainNFTDrawer.value = true;
  };
  const hideCrossChainNFTDrawer = () => {
    visibleCrossChainNFTDrawer.value = false;
  };
  return {
    visibleCrossChainNFTDrawer,
    showCrossChainNFTDrawer,
    hideCrossChainNFTDrawer,
  };
}

export function useToggleNFTDetailsDrawer() {
  const visibleNFTDetailsDrawer = ref(false);
  const showNFTDetailsDrawer = () => {
    visibleNFTDetailsDrawer.value = true;
  };
  const hideNFTDetailsDrawer = () => {
    visibleNFTDetailsDrawer.value = false;
  };
  return {
    visibleNFTDetailsDrawer,
    showNFTDetailsDrawer,
    hideNFTDetailsDrawer,
  };
}

export function useToggleTokenDetailsDrawer() {
  const visibleTokenDetailsDrawer = ref(false);
  const showTokenDetailsDrawer = () => {
    visibleTokenDetailsDrawer.value = true;
  };
  const hideTokenDetailsDrawer = () => {
    visibleTokenDetailsDrawer.value = false;
  };
  return {
    visibleTokenDetailsDrawer,
    showTokenDetailsDrawer,
    hideTokenDetailsDrawer,
  };
}

export function useToggleTokenBlindBoxDetailsDrawer() {
  const visibleTokenBlindBoxDetailsDrawer = ref(false);
  const showTokenBlindBoxDetailsDrawer = () => {
    visibleTokenBlindBoxDetailsDrawer.value = true;
  };
  const hideTokenBlindBoxDetailsDrawer = () => {
    visibleTokenBlindBoxDetailsDrawer.value = false;
  };
  return {
    visibleTokenBlindBoxDetailsDrawer,
    showTokenBlindBoxDetailsDrawer,
    hideTokenBlindBoxDetailsDrawer,
  };
}

export function useToggleGgmSendTokenModal() {
  const visibleGgmSendTokenModal = ref(false);
  const showGgmSendTokenModal = () => {
    visibleGgmSendTokenModal.value = true;
  };
  const hideGgmSendTokenModal = () => {
    visibleGgmSendTokenModal.value = false;
  };
  return {
    visibleGgmSendTokenModal,
    showGgmSendTokenModal,
    hideGgmSendTokenModal,
  };
}
export function useToggleGcoinSendTokenModal() {
  const visibleGcoinSendTokenModal = ref(false);
  const showGcoinSendTokenModal = () => {
    visibleGcoinSendTokenModal.value = true;
  };
  const hideGcoinSendTokenModal = () => {
    visibleGcoinSendTokenModal.value = false;
  };
  return {
    visibleGcoinSendTokenModal,
    showGcoinSendTokenModal,
    hideGcoinSendTokenModal,
  };
}

