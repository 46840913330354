
import { defineComponent, ref } from "vue";
import {
  Assets_Type,
  Transaction_Type,
  SYMBOL_GGM,
  GONSCAN_URL,
  GONSCAN_ACTIONS_URL
} from "@/constants/index";
import { endDayFormat } from "@/utils";
import { shortenAddress } from "vue3-dapp-boot";
import { METAVERSE_TYPE } from "@/constants/index";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    day: {
      type: String,
      required: true,
    },
    address: {
      type: String,
      required: true,
    },
    param2: {
      type: String,
      required: true,
    },
    logIndex: {
      type: String,
      required: true,
    },
    hash: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    }
  },
  setup() {
    const loading = ref(false);

    const handleClick = () => {
      loading.value = !loading.value;
    };

    const openUrl = (hash: string,logIndex:string) => {
      window.open(GONSCAN_ACTIONS_URL + hash +'@'+logIndex);
    };

    return {
      loading,
      handleClick,
      Assets_Type,
      Transaction_Type,
      endDayFormat,
      SYMBOL_GGM,
      openUrl,
      shortenAddress,
      METAVERSE_TYPE,
    };
  },
});
