<template>
  <a-result class="mt-10" status="404" >
    <template #extra>
      <a-button type="primary">
        <a href="https://playmonstergalaxy.com/home">Back Home</a>
<!--        <router-link to="https://playmonstergalaxy.com/home">Back Home</router-link>-->
      </a-button>
    </template>
  </a-result>
</template>
