
import {ComponentPublicInstance, defineComponent, getCurrentInstance, ref} from "vue";
import { getPlacement, getTimestamp, getDeadline } from "@/utils";
import {
  User_Type,
  IOptionUser,
  DEFAULT_IMG,
  GET_ASSET20_BODY,
  ASSET20_TRANSFER,
} from "@/constants";
import { queryBalance } from "@/api";

import { useEthers } from "vue3-dapp-boot";
import { ethers } from "ethers";
import { couldapiOperator } from "@/api/operator";

import { accountStore } from "@/store";
import { storeToRefs } from "pinia";

import { balanceFormat, getHash } from "@/utils";

import WalletConnect from "@walletconnect/client";

import { CaretDownFilled } from "@ant-design/icons-vue";
//wang
import { Modal } from 'ant-design-vue';
import {h } from 'vue';
import axios from "axios";
let timeout: any;
let currentValue = "";
const cdapi =process.env.VUE_APP_CD_API?.toLocaleLowerCase() as string;
function fetch(accountIdValue: string, callback: any) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = accountIdValue;

  function fake() {
    axios
      .post(cdapi+'getuserinfo', {
        uid: accountIdValue,
      })
      .then((d) => {
        if (currentValue === accountIdValue) {
          const result = ref();
          result.value = [d.data?.message];
          const data: any[] = [];
          result.value.forEach((r: any) => {
            console.log(r,77)
            data.push({
              value: r.account,
              label: r.uuid,
            });
          });
          callback(data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  timeout = setTimeout(fake, 300);
}
export default defineComponent({
  name: "GgmSendToken",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    CaretDownFilled
  },
  setup(props: any, context: { emit: (arg0: string, arg1: any) => void }) {
    const instance = getCurrentInstance() as ComponentPublicInstance<any>;
    const modalclose = e => {
      context.emit('close',"")
    }

    const GCOIN_CONTRACT = process.env.VUE_APP_GCOIN_CONTRACT?.toLocaleLowerCase() as string

    const level1Value = ref();
    const amountValue = ref();
    const accountIdValue = ref();
    const remarkValue = ref();

    const isError = ref(false)
    const isErrorMessage = ref("")

    const checked = ref(false);
    const placement = ref(String(getPlacement()));

    const list = ref();
    const loading = ref(false);
    let urlMapping = new Map();

    const { address } = useEthers();

    const store = accountStore();
    const { getAId, getContacts } = storeToRefs(store);

    const contactsList = getContacts;
    let retObj: IOptionUser = {
      label: "",
      value: ""
    };
    //wang
    const data1 = ref<any[]>([]);
    const myhandleSearch = (val: string) => {
      fetch(val, (d: any[]) => (data1.value = d));
    };
    const myhandleChange = (val: string) => {
      accountIdValue.value = val;

      fetch(val, (d: any[]) => (data1.value = d));
    };

    if (contactsList.value.length == 0) {
      contactsList.value.push(retObj)
    } else if (contactsList.value.length == 2) {
      contactsList.value.splice(0, 1)
    }

    const deadline = getDeadline();

    const handleSendClick = () => {

      if (amountValue.value == undefined) {
        isErrorMessage.value = "amount is not null"
        isError.value = true
        return
      }

      if (accountIdValue.value == undefined) {
        isErrorMessage.value = "accountId is not null"
        isError.value = true
        return
      }
      //wang
      if (amountValue.value > balanceFormat(
        balanceInfo.value?.account?.assets[0]?.amount
      )) {
        isErrorMessage.value = "Amount out of range"
        isError.value = true
        return
      }

      const data = ref(GET_ASSET20_BODY);
      data.value.id = getTimestamp();
      const toId = String(accountIdValue.value).split("-")[0]
      const params = [
        {
          sender: sessionStorage.getItem('cloud_wallet') as string,
          contractAddr: GCOIN_CONTRACT,
          fromId: Number(getAId.value),
          toId: Number(toId),
          amount: String(ethers.utils.parseEther(String(amountValue.value))),
          deadline: deadline,
        },
      ];
      data.value.params = params;
      couldapiOperator(data.value).then((res) => {
        could_signTypedData(res);
      });
    };
    let g=instance.proxy.$g;
    const could_signTypedData = async (typedData: any) => {

      const fromAccount = new instance.proxy.$accert.Account(instance.proxy.$p,sessionStorage.getItem('tid'));

      const t2 = new instance.proxy.$accert.Token(instance.proxy.$p, 'GCoin');
      fromAccount.wallet = g; // 设置wallet提供签名功能
      const signature = await g.sign(typedData.result.typedData,{contractAddr: GCOIN_CONTRACT});
      const signTypedData = {
        jsonrpc: typedData.jsonrpc,
        id: typedData.id,
        types: typedData.result.typedData.types,
        primaryType: typedData.result.typedData.primaryType,
        domain: typedData.result.typedData.domain,
        message: typedData.result.typedData.message,
      };
      const msgParams = [address.value, JSON.stringify(signTypedData)];


          const data = ref(ASSET20_TRANSFER);
          data.value.id = getTimestamp();

          const hash = getHash(signTypedData);

          const toId = String(accountIdValue.value).split("-")[0]
          const params = [
            {
              sender: sessionStorage.getItem('cloud_wallet') as string,
              contractAddr: GCOIN_CONTRACT,
              fromId: Number(getAId.value),
              toId: Number(toId),
              amount: String(
                ethers.utils.parseEther(String(amountValue.value))
              ),
              hash: typedData.result.hash,
              signature: String(signature),
              deadline: deadline,
            },
          ];
          data.value.params = params;
      couldapiOperator(data.value).then(() => {

            context.emit("eventRefresh", "");
          });

    };

    const balanceInfo = ref();
    queryBalance().then((res) => {
      console.log(res,331)
      balanceInfo.value = res.data;
      loading.value = false;
    });

    const handleMaxClick = async () => {
      amountValue.value = balanceFormat(
        balanceInfo.value?.account?.assets[0]?.amount
      );
    };

    const filterOption = (input: string, option: any) => {
      accountIdValue.value = input
      return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const focus = () => {
      isError.value = false
    }

    return {
      level1Value,
      amountValue,
      accountIdValue,
      remarkValue,
      checked,
      placement,
      list,
      loading,
      User_Type,
      DEFAULT_IMG,
      urlMapping,
      balanceInfo,
      contactsList,
      isError,
      isErrorMessage,
      balanceFormat,
      filterOption,
      handleSendClick,
      handleMaxClick,
      focus,
      //wang
      myhandleSearch,
      myhandleChange,
      data1,
      modalclose,

    };
  },
});
