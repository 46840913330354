
import { defineComponent, ref, onMounted, watchEffect } from "vue";
import GGM from "@/components/card/ggm.vue";
import MogaNFT from "@/components/card/nft.vue";

import { NFT_Type, Assets_Type } from "@/constants";

import {
  useToggleNFTDetailsDrawer,
  useToggleSendToDrawer,
  useToggleSendNFTDrawer,
  useToggleSendTokenDrawer,
} from "@/hooks/useToggleWithdraw";

import NFTDetailsDrawer from "@/components/drawer/nft.vue";
import SendToDrawer from "@/components/drawer/sendTo.vue";
import SendNFTDrawer from "@/components/drawer/sendNFT.vue";
import SendTokenDrawer from "@/components/drawer/sendToken.vue";

import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  components: {
    GGM,
    MogaNFT,
    NFTDetailsDrawer,
    SendToDrawer,
    SendNFTDrawer,
    SendTokenDrawer,
  },
  setup() {

    const {
      visibleNFTDetailsDrawer,
      showNFTDetailsDrawer,
      hideNFTDetailsDrawer,
    } = useToggleNFTDetailsDrawer();

    const { visibleSendToDrawer, showSendToDrawer, hideSendToDrawer } =
      useToggleSendToDrawer();

    const { visibleSendNFTDrawer, showSendNFTDrawer, hideSendNFTDrawer } =
      useToggleSendNFTDrawer();

    const { visibleSendTokenDrawer, showSendTokenDrawer, hideSendTokenDrawer } =
      useToggleSendTokenDrawer();

    const sendType = ref(Assets_Type.NFT);
    const assetType = ref(0);

    const item = ref();
    const openNFTDetailsDrawer = async (res: any) => {
      item.value = res;
      sendType.value = Assets_Type.NFT;
      showNFTDetailsDrawer();
    };

    const address = ref("");
    const accountId = ref("");
    const user = ref();
    const closeSendToDrawerAndOpenSendDrawer = async (res: any) => {
      user.value = res;
      if (res.type == Assets_Type.NFT) {
        hideSendToDrawer();
        showSendNFTDrawer();
      } else {
        hideSendToDrawer();
        showSendTokenDrawer();
      }
    };

    const closeNFTDetailsAndOpenSendNFTDrawer = async () => {
      hideNFTDetailsDrawer();
      showSendToDrawer();
    };

    const openSendToDrawer = async () => {
      sendType.value = Assets_Type.Token;
      showSendToDrawer();
    };

    const closeSendNFTDrawerAndOpenSendToDrawer = async (item: any) => {
      address.value = item?.address;
      accountId.value = item?.accountId;
      hideSendNFTDrawer();
      showSendToDrawer();
    };

    const closeSendNFTDrawerAndRefresh = async () => {
      hideSendNFTDrawer();
      setTimeout(function () {
        location.reload();
      }, 2000);
    };

    const closeSendTokenDrawerAndOpenSendToDrawer = async (item: any) => {
      address.value = item?.address;
      accountId.value = item?.accountId;
      hideSendTokenDrawer();
      showSendToDrawer();
    };

    const closeSendTokenDrawerAndRefresh = async () => {
      hideSendTokenDrawer();
      setTimeout(function () {
        location.reload();
      }, 2000);
    };

    const route = useRoute();

    const cardAccountId = ref();

    watchEffect(async () => {
      if (route.params.id == undefined) {
        return;
      }
      cardAccountId.value = Number(route.params.id);
    });

    return {
      activeKey: ref("1"),
      NFT_Type,

      openNFTDetailsDrawer,
      visibleSendNFTDrawer,
      showSendNFTDrawer,
      hideSendNFTDrawer,

      visibleNFTDetailsDrawer,
      showNFTDetailsDrawer,
      hideNFTDetailsDrawer,
      closeNFTDetailsAndOpenSendNFTDrawer,

      // Send To
      visibleSendToDrawer,
      showSendToDrawer,
      hideSendToDrawer,
      openSendToDrawer,
      closeSendToDrawerAndOpenSendDrawer,
      sendType,
      assetType,
      address,
      accountId,
      item,
      user,

      // Send To NFT
      closeSendNFTDrawerAndOpenSendToDrawer,
      closeSendNFTDrawerAndRefresh,

      // Send To Token
      visibleSendTokenDrawer,
      showSendTokenDrawer,
      hideSendTokenDrawer,
      closeSendTokenDrawerAndOpenSendToDrawer,
      closeSendTokenDrawerAndRefresh,

      cardAccountId,
    };
  },
});
