<template>
  <div class="marquee-container">
    <div class="marquee-content" :style="{ animationDuration: animationDuration }">
      {{ content }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      required: true
    },
    speed: {
      type: Number,
      default: 5 // 默认速度，可以根据需要调整
    }
  },
  computed: {
    animationDuration() {
      return `${this.content.length / this.speed}s`;
    }
  }
};
</script>

<style>
.marquee-container {
  width: 100%;
  overflow: hidden;
}

.marquee-content {
  white-space: nowrap;
  animation: marqueeAnimation linear infinite;
}

@keyframes marqueeAnimation {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
</style>
