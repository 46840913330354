
import { defineComponent, ref } from "vue";
import {
  Assets_Type,
  Transaction_Type,
  SYMBOL_GGM,
  GONSCAN_URL,
  GONSCAN_ACTIONS_URL
} from "@/constants/index";
import { endDayFormat } from "@/utils";
import { toFormatEthers } from "@/wallet/utils";

import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  props: {
    logoUrl: {
      type: String,
      required: true,
    },
    transactionType: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    day: {
      type: String,
      required: true,
    },
    param1: {
      type: String,
      required: true,
    },
    param2: {
      type: String,
      required: true,
    },
    logIndex: {
      type: String,
      required: true,
    },
    isIn: {
      type: String,
      required: true,
    },
    isBWO: {
      type: Boolean,
      required: true,
    },
    hash: {
      type: String,
      required: true,
    },
  },
  setup() {
    const loading = ref(false);

    const handleClick = () => {
      loading.value = !loading.value;
    };

    const openUrl = (hash: string,logIndex:string) => {
      window.open(GONSCAN_ACTIONS_URL + hash +'@'+logIndex);
    };

    const router = useRouter();

    const goAccountWallet = (id: string) => {
      router.push({
        name: "accountWallet",
        params: {
          id,
        },
      });
    }

    return {
      loading,
      handleClick,
      Assets_Type,
      Transaction_Type,
      endDayFormat,
      SYMBOL_GGM,
      openUrl,
      toFormatEthers,
      goAccountWallet,
    };
  },
});
