import { ref } from "vue";

// 屏幕宽度
const windowWidth = ref(0);
// 获取屏幕尺寸
export function getWindowWindth() {
  return (windowWidth.value = window.innerWidth);
}

export function getHeight() {
  return "1080px";
}

import { isMobile } from "@/utils";

export function getPlacement() {
  if (isMobile) {
    return "bottom";
  } else {
    return "right";
  }
}

export function shortenAddress(address: string): string {
  // if (isAddress(address)) {
  return address.slice(0, 6) + "..." + address.slice(-4);
  // } else {
  //   return ''
  // }
}

// import { ref } from "vue";

// import moment from "moment";
// import { min } from "lodash";

// window.moment = moment;

// moment.suppressDeprecationWarnings = true;

// export enum TYPE {
//   OPEN = 1,
//   WAIT = 2,
//   UNOPENED = 3,
// }

// export const getType = (obj: number | string, date: string, hashRateLimit: number) => {
//   let type = TYPE.UNOPENED;

//   if (moment().isAfter(moment(date))) {
//     type = TYPE.WAIT;
//   }

//   if (hashRateLimit == 0 && moment().isAfter(moment(date))) {
//     type = TYPE.OPEN;
//   }

//   return type;
// };

// export const getTypeByDay = (date: string) => {
//   if (moment().isAfter(moment(date))) {
//     return TYPE.OPEN;
//   }
//   return TYPE.UNOPENED;
// };

// export const getDay = (date: string) => {
//   return moment().diff(moment(date), "days") + 1;
// };

// export const getDayStr = (date: string) => {
//   if (Number(date) == 0) {
//     return "";
//   }
//   return moment(date).format("(YYYY-MM-DD h:mm:ss)");
// };

// export const getPercentage = (mine: any, total: any) => {
//   if (mine == undefined ? 0 : mine == 0) {
//     return 0;
//   }

//   return (mine == undefined ? 0 : mine / total == undefined ? 0 : total * 100).toFixed(2);
// };

// export function numFormat(num: string) {
//   const res = num.toString().replace(/\d+/, function (n) {
//     // 先提取整数部分
//     return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
//       return $1 + ",";
//     });
//   });
//   return res;
// }

// import { request } from "@/avatar-lib/utils";

// export function getNetTime(){
//   return request.get<any>("http://api.m.taobao.com/rest/api3.do?api=mtop.common.getTimestamp");
// }

import moment, { months } from "moment";
// import momenttz from 'moment-timezone';
// momenttz.tz("America/Los_Angeles").format();

window.moment = moment;

moment.suppressDeprecationWarnings = true;
moment.locale("us");

export function endDayFormat(date: any) {
  if (date == 0) {
    return "";
  }
  return moment(Number(date * 1000)).fromNow();
}
// export function customTime() {
//   //目标表时间，西八区 .endOf("day")
// let timeZone = -8;
// //获取本地时间与格林威治时间的时间差(注意是分钟，记得转换)
// let diff = new Date().getTimezoneOffset();
// //根据本地时间和时间差获得格林威治时间
// let absTime = new Date().getTime() + diff * 60 * 1000;
// //根据格林威治时间和各地时区，得到各地时区的时间
// let localTime = new Date(absTime + timeZone * 60 * 60 * 1000);
// //处理夏令时(isDST为自己封装的处理方法)
// // if(isDST(localTime, country)) {
// //   localTime = new Date(absTime + (timeZone + 1) * 60 * 60 * 1000)；
// // }
// return localTime;
// }

export function getTimestamp() {
  return moment().valueOf();
}

export function getDeadline() {
  return Number(moment().format("X")) + 24 * 60 * 60;
}

// import { connectors } from "@/wallet/hooks/useConnectors"

// export function eth_signTypedData() {
//   console.log(connectors)
// }

export function balanceFormat(balance: string) {
  // const res = num.toString().replace(/\d+/, function (n) {
  //   // 先提取整数部分
  //   return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
  //     return $1 + ",";
  //   });
  // });
  return Number(balance).toFixed(4);
}

export function getHash(signTypedData: any) {
  const ethSigUtil = require("@metamask/eth-sig-util");
  const hash = ethSigUtil.TypedDataUtils.eip712Hash(
    signTypedData,
    ethSigUtil.SignTypedDataVersion.V4
  );
  return "0x" + hash.toString('hex');
}
export function truncateString(str: string, maxLength: number): string {
  if (str.length <= maxLength) {
    return str;
  }

  const truncatedStr: string = str.substr(0, maxLength / 2 - 1) + "..." + str.substr(str.length - maxLength / 2 + 2);
  return truncatedStr;
}

