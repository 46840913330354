// src/store/loading.ts
import { ref } from 'vue';

export const globalLoading = ref(false);

export const showGlobalLoading = () => {
    globalLoading.value = true;
};

export const hideGlobalLoading = () => {
    globalLoading.value = false;
};
