import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-4" }
const _hoisted_2 = { class: "flex flex-col justify-center space-y-2" }
const _hoisted_3 = { class: "text-center black-title text-xl font-semibold" }
const _hoisted_4 = { class: "text-center text-lg blue-title" }
const _hoisted_5 = { class: "px-14 w-full" }
const _hoisted_6 = {
  key: 0,
  class: "flex flex-col items-center space-y-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createBlock(_component_a_drawer, {
    title: "",
    placement: _ctx.placement,
    visible: _ctx.visible,
    height: "80%",
    destroyOnClose: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.item.name), 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString("# " + _ctx.item.itemId), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("img", null, null, 512), [
            [_directive_lazy, _ctx.item.imageUrl]
          ])
        ]),
        (_ctx.isMe)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("span", {
                class: "text-center text-lg blue-title cursor-pointer mb-1",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
              }, "Send"),
              _createElementVNode("span", {
                class: "text-center text-lg blue-title cursor-pointer mb-1",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleCrossChainClick && _ctx.handleCrossChainClick(...args)))
              }, "Cross-chain Send")
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["placement", "visible"]))
}