import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import WalletView from "../views/Wallet/Index.vue";
import WallectByAccountIdView from "../views/Wallet/Account.vue";
import ActivityView from "../views/Activity/Index.vue";
import AccountActityView from "../views/Activity/Account.vue";
import AccountView from "../views/Account/Index.vue";
import TestView from "../views/Test/Index.vue";
import TestView1 from "../views/Test/Index.bek.vue";
import GetmogaView from "../views/web/Getmoga.vue";
import SalemogaView from "../views/Sale/Salemoga.vue";
import error from "../views/error.vue";
import TradeView from "../views/Trade/index.vue";

import WalletViewv2 from "../views/app/wallet.vue";
import joinView from "../views/join/index.vue";



const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: '/home',
    meta: { title: "", top: true },
  },
  {
    path: "/home",
    name: "home",
    component: TestView,
    meta: { title: "", top: true },
  },
  {
    path: "/test1",
    name: "test1",
    component: TestView1,
    meta: { title: "", top: true },
  },
  // {
  //   path: "/",
  //   redirect: "/wallet",
  //   meta: { title: "", top: true },
  // },
  {
    path: "/wallet",
    name: "wallet",
    component: WalletViewv2,
    meta: { title: "", top: true },
  },
  {
    path: "/wallet/:id",
    name: "accountWallet",
    component: WallectByAccountIdView,
    meta: { title: "", top: false },
  },
  {
    path: "/activity",
    name: "activity",
    component: ActivityView,
    meta: { title: "", top: true },
  },
  // {
  //   path: "/activity",
  //   name: "accountActivity",
  //   component: AccountActityView,
  //   meta: { title: "", top: true },
  // },
  {
    path: "/account",
    name: "account",
    component: AccountView,
    meta: { title: "", top: true },
  },
  {
    path: "/getmoga",
    name: "getmoga",
    component: GetmogaView,
    meta: { title: "", top: true },
  },
  {
    path: "/salemoga",
    name: "salemoga",
    component: SalemogaView,
    meta: { title: "", top: true },
  },
  {
    path: "/temp",
    name: "temp",
    component: SalemogaView,
    meta: { title: "", top: true },
  },
  {
    path: "/trade",
    name: "trade",
    component: TradeView,
    meta: { title: "", top: true },
  },
  {
    path: "/join",
    name: "join",
    component: joinView,
    meta: { title: "", top: true },
  },

  {
    path: '/404',
    name: '404',
    component: error,
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404'
  }
];
console.log(process.env.BASE_URL,79809098)
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  // if (to.path == '/user/login' || to.path == '/user/register') {
  //   // 登录或者注册才可以往下进行
  //   next();
  // } else {
  //   // 获取 token
  //   const token = localStorage.getItem('Authorization');
  //   // token 不存在
  //   if (token === null || token === '') {
  //
  //     next('/user/login');
  //   } else {
  //     next();
  //   }
  // }
  next()

});
export default router;
