
export const DEFAULT_PRECISION = 18;

export const MAX_PRECISION = 28;

export const DEFAULT_USER_PRECISION = 6;

export const INFURA_ID = "863c34ed0c4040409f4f61fecd08491e";

export const MAX_ALLOWANCE_AMUNT =
  '115792089237316195423570985008687907853269984665640564039457584007913129639935';
