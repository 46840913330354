const os = function os() {
  const ua = navigator.userAgent;
  const isWindowsPhone = /(?:Windows Phone)/.test(ua);
  const isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone;
  const isAndroid = /(?:Android)/.test(ua);
  const isIos = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios
  const isFireFox = /(?:Firefox)/.test(ua);
  const isTablet =
    /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua));
  const isPhone = /(?:iPhone)/.test(ua) && !isTablet;
  const isPc = !isPhone && !isAndroid && !isSymbian;
  const isMobile = !isPc;
  return {
    isTablet,
    isPhone,
    isAndroid,
    isIos,
    isFireFox,
    isPc,
    isMobile,
  };
};

const browser = os();

export const isMobile = browser.isMobile;

export default browser;
