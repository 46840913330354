import apolloClient from "@/utils/ggmgraphsql";
import gql from "graphql-tag";
import {accountStore} from "@/store";
import { storeToRefs } from "pinia";
import {getTimestamp} from "@/utils";

const METAVERSE = process.env.VUE_APP_METAVERSE?.toLocaleLowerCase();
const WORLD = process.env.VUE_APP_WORLD?.toLocaleLowerCase();


export function ggmqueryOrders(first: number,skip:number,status:string,pairId:string) {
    const store = accountStore();
    const { getAccountId } = storeToRefs(store);
    const id = METAVERSE + "@" + getAccountId.value;
    const GET_BALANCE = gql`
    query Orders($first: Int, $skip: Int, $status: String, $pairId: String) {
  orders(first: $first, skip: $skip, orderBy: price, where: {status: $status}) {
    id
    maker
    taker
    side
    totalAmount
    remainingAmount
    price
    listingTime
    expirationTime
    status
  }
  p2Ppair(id: $pairId) {
    id
    validOrderCount
  }
}
  `;
    return apolloClient.query({
        query: GET_BALANCE,
        variables: {
            first,
            skip,
            status,
            pairId
        },
    });
}
export function Activities(first: number,skip:number) {
    const account = sessionStorage.getItem('tid');
    const GET_BALANCE = gql`
    query Activities($first: Int, $skip: Int, $account: String) {
  activities(
    first: $first
    skip: $skip
    orderBy: createAt
    orderDirection: desc
    where: {or: [{from: $account}, {to: $account}]}
  ) {
    id
    from
    to
    amount
    price
    createAt
    status
    txHash
  }
}
  `;
    return apolloClient.query({
        query: GET_BALANCE,
        variables: {
            first,
            skip,
            account
        },
    });
}
