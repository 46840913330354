import { request,ggmrequest } from "@/wallet/utils";

export async function apiBridge(data: any): Promise<any> {
  return request.post<any>(`/bridge`, data);
}
export async function apiBridgev2(data: any): Promise<any> {
  return request.post<any>(``, data);
}
export async function ggmApiBridge(data: any): Promise<any> {
  return ggmrequest.post<any>(``, data);
}

