

import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons-vue';
import {defineComponent, onMounted, ref, reactive, watch, getCurrentInstance, computed, watchEffect,ComponentPublicInstance} from 'vue';
import {useRoute, useRouter,onBeforeRouteUpdate} from "vue-router";
import { isMobile } from "@/utils";
import couldwallet from "@/components/couldwallet/couldwallet.vue";
import Clipboard from 'clipboard';
import { toFormatEthers } from "@/wallet/utils";
import { balanceFormat } from "@/utils";
import { globalLoading, showGlobalLoading, hideGlobalLoading } from '@/store/loading';
import Marquee from "@/components/cus/Marquee.vue";
export default defineComponent({
  components: {
    couldwallet,
    Marquee,
    UserOutlined,
    LaptopOutlined,
    NotificationOutlined,
  },
  setup() {
    const openKeys=ref(['sub1']);
    const router = useRouter();
    const route = useRoute();

    const selectedKeys=ref([route.path])
    watch(() => route.path,() => {
      selectedKeys.value=[route.path]
        },{immediate: true,deep: true}
    )
    const islogin=ref<boolean>(false);

    if (sessionStorage.getItem('tid')){
      islogin.value=true
    }
    let accountActions = ref<any>(null);
    accountActions.value=JSON.parse(localStorage.getItem('account') as string)
    const pislogin= (e) => {
      islogin.value=e
      // setTimeout(() => {
        location.reload()
      // },100)
    }

    const sideopen= () => {
      document.getElementById('isMobileSidebarOpen')?.setAttribute('style', 'display:block');
      document.getElementById('isMobileSidebarClose')?.setAttribute('style', 'display:none');
      document.getElementById('rgbalayer')?.setAttribute('style', 'display:block');
    }
    const sideclose= () => {
      document.getElementById('isMobileSidebarOpen')?.setAttribute('style', 'display:none');
      document.getElementById('isMobileSidebarClose')?.setAttribute('style', 'display:block');
      document.getElementById('rgbalayer')?.setAttribute('style', 'display:none');
    }
    const loginVisible= ref<boolean>(false);
    const showloginmodal= () => {
      loginVisible.value=true
    }
    const announcementVisible= ref<boolean>(false);
    const showannouncementmodal= () => {
      announcementVisible.value=!announcementVisible.value
    }

    const joinVisible= ref<boolean>(false);
    const joinmodal= () => {
      announcementVisible.value=!joinVisible.value
    }

    const infoOpen= ref<boolean>(false);
    const collapsed= ref<boolean>(true);
    const instance = getCurrentInstance() as ComponentPublicInstance<any>;
    let gcoin=ref<number>(0)
    let ggm=ref<any>(0)

    const gcoinGgm= async () => {
      const t1 = new instance.proxy.$accert.Token(instance.proxy.$p, 'GCoin');
      const tggm = new instance.proxy.$accert.Token(instance.proxy.$p, 'GGM');
      gcoin.value = await t1.balanceOf(accountActions.value.id)||0;
      ggm.value = await tggm.balanceOf(accountActions.value.id)||0;
    }
    if (sessionStorage.getItem('tid')){
      gcoinGgm()
    }

    const  showInfo= async () => {
      infoOpen.value = !infoOpen.value
      if(infoOpen.value==true){
        gcoinGgm()
      }
    }
    const Communitystep=1
    const loginstep=1
    const handleClick = e => {
      if(e.item.path.indexOf('http')<0){
        router.push({
          name:e.item.path,
        })
      }else{
        window.open(e.item.path)
        return
      }
      //判断是否是一级菜单，一级菜单item.keyPath长度为1，二级菜单item.keyPath长度为2，清空二级菜单展开数组openKeys
      if (e.keyPath.length == 1) {
        sessionStorage.removeItem('openKeys')
        openKeys.value = []
      }
    };
    const handfooter = e => {
      const footerkey=e
    }
    const titleClick = e => {
      console.log('22777822', e);
    };
    const handleClose = (e: MouseEvent) => {
      loginVisible.value = false;
    };
    const onOpenChange = keys => {
      let keyArr: any[]=[]
      if (keys.length > 0) {
        //取最后一项，最后一项才是你当前展开的菜单
        keyArr.push(keys[keys.length - 1])
      }
      openKeys.value = keyArr
      sessionStorage.setItem('openKeys', JSON.stringify(keyArr))
    }
    onMounted(() => {
      const openKey = sessionStorage.getItem('openKeys')
      if (openKey) {
        openKeys.value = JSON.parse(openKey)
      }
    })
    const toggleCollapsed = () => {
      collapsed.value = !collapsed.value;
    };
    watchEffect(async () => {
      if (isMobile) {
        collapsed.value = false;
      }

    });
    const ggmvisible = ref<boolean>(false);
    const ggmvisiblehandleOk = (e: MouseEvent) => {
      console.log(e);
      ggmvisible.value = true;
    };
    const ggmvisiblehandleClose = (e: MouseEvent) => {
      ggmvisible.value = false;
    };
    const couldVisible= ref<boolean>(false);
    const childRef = ref<any>(null)

    const couldlogin = () => {
      childRef.value.login();
    }
    const Logout= () => {
      childRef.value.logout();
    }

    const handleMenuSelect = ({ item }) => {
      if(item.external){
        selectedKeys.value=[route.path]
      }
    };


    // 文本内容
    // const textToCopy = ref('要复制的文本内容');
   // 复制文本
    const copyText = () => {
      const clipboard = new Clipboard('.copy-btn', {
        text: () => accountActions.value
      });

      clipboard.on('success', (e) => {
        console.log('复制成功:', e.text);
        clipboard.destroy(); // 销毁clipboard实例
      });

      clipboard.on('error', (e) => {
        console.log('复制失败:', e.action, e.trigger);
        clipboard.destroy(); // 销毁clipboard实例
      });

      // clipboard.onClick({ type: 'button' });
    };
    return {
      sideopen,
      sideclose,
      handleClick,
      titleClick,

      loginVisible,
      islogin,
      pislogin,
      announcementVisible,
      showannouncementmodal,
      joinVisible,
      joinmodal,
      infoOpen,
      showInfo,

      Communitystep,
      loginstep,

      handleClose,
      showloginmodal,
      selectedKeys1: ref(['2']),
      selectedKeys2: ref([route.path]),
      selectedKeys,

      openKeys,
      onOpenChange,

      ggmvisible,
      ggmvisiblehandleOk,
      ggmvisiblehandleClose,

      collapsed,
      toggleCollapsed,
      couldVisible,
      couldlogin,
      childRef,
      Logout,
      accountActions,
      gcoin,
      ggm,
      copyText,
      handleMenuSelect,
      balanceFormat,
      showGlobalLoading,
      globalLoading

    };
  },
});
