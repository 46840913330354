import { useEthers } from "vue3-dapp-boot";
import { ref, watch, markRaw, toRaw } from "vue";
import store from "store2";
import { BroadcastResult, ITransaction, Transaction_Status } from "@/wallet/types/ICommon";
import { TransactionLooper } from "@/wallet/core/TransactionLooper";
import { find, remove } from "lodash";
import { useTransactionStore } from "@/wallet/store/transaction";
import moment from 'moment';

const txs = ref([] as Array<any>);
const transactionLoopers = ref([] as Array<TransactionLooper>);

export function useLoopers() {

  const { address } = useEthers();
  const transactionStore = useTransactionStore();

  const removeTransaction = (receipt: BroadcastResult) => {
    const target = find(txs.value, (item) => item.transactionHash === receipt.transactionHash);
    if (target) {
      if (receipt.status === 1) {
        target.status = Transaction_Status.Success;
        if (transactionStore.hasCurrentTx) {
          transactionStore.visibleTransactionSubmitted = false;
          transactionStore.visibleSuccess = true;
          transactionStore.broadcastResult = receipt;
        }
      } else {
        target.status = Transaction_Status.Error;
        if (transactionStore.hasCurrentTx) {
          transactionStore.visibleTransactionSubmitted = false;
          transactionStore.visibleFail = true;
        }
      }
      remove(transactionLoopers.value, (item: TransactionLooper) => item.transactionHash === receipt.transactionHash);
      // console.log(txs);
      store.set(address.value, txs);
    }
  }

  watch(address, (newVal) => {
    if (!newVal) {
      return;
    }
    const transactionList = store.get(address.value, [] as Array<ITransaction>);
    txs.value = transactionList._value || [];
    const loopers = [] as Array<TransactionLooper>;
    if (transactionList.length > 0) {
      transactionList.forEach((tx: ITransaction) => {
        if (tx.status === Transaction_Status.Pending) {
          const looper = new TransactionLooper(
            tx.transactionHash,
            tx.description,
            tx.status,
            removeTransaction,
            moment().toString()
          );
          loopers.push(looper);
        }
      });
    }
    transactionLoopers.value = loopers;
  });

  const addTransaction = (tx: ITransaction) => {
    const list: Array<ITransaction> = toRaw(txs.value);
    list.unshift(tx);
    console.log('addTransaction ...');
    const looper = new TransactionLooper(
      tx.transactionHash,
      tx.description,
      tx.status,
      removeTransaction,
      moment().toString()
    );
    transactionLoopers.value.unshift(looper);
    store.set(address.value, list);
  }

  const clearAllTransaction = () => {
    txs.value = [];
    transactionLoopers.value = [];
    store.set(address.value, []);
  }

  return {
    txs,
    transactionLoopers,
    addTransaction,
    removeTransaction,
    clearAllTransaction
  };
}
