<template>

  <div class="main  flex flex-col items-center  mx-auto mt-2 pb-5" >
    <div class="w-96">
    </div>
    <a-tabs v-model:activeKey="activeKey"  class="text-white mt-4 w-96" change="" @change="changetab">
      <a-tab-pane disabled key="1" tab="Intro" >
        1
      </a-tab-pane>
      <a-tab-pane  key="2" tab="Trade" >
        <div class="content w-full  mt-4 bg-[#5b4973] rounded-2xl" style="border: 1px solid rgba(255, 255, 255, 0.24);">
          <div class="top flex flex-row  p-3   items-center">
            <div class="lt rounded-full bg-[#1F1927] w-10 h-10 mr-4 flex items-center"  >
              <img width="85" src="../../assets/app/wallet/ggm.png">
            </div>
            <div class="gt flex flex-row justify-between bg-[#1F1927] flex-1 p-2.5 rounded-md items-center">
              <div class="yue inline-flex roboto">
                   <div class="text-white ">GGM Balance:</div>
                   <div style="color: #01bd35">{{ balanceFormat(GGM) }}</div>
              </div>
              <div @click="sellvisible=true" class="sell JungleAdventurer text-white rounded px-5 py-1 bg-[#01BD35]" style="border: 1px solid rgba(255, 255, 255, 0.40);">
                sell
              </div>
            </div>
          </div>
          <div class="ct bg-[#1F1927] rounded-2xl m-3" style="overflow: hidden">
            <a-table class="m-2 "  :scroll="{x:true}"    :columns="columns" :data-source="orders_list">

              <template #bodyCell="{ column, record }">

                <template v-if="column.key === 'price'">
                  <a style="color: #a7a7ab">
                    {{ record.price }}
                  </a>
                </template>
                <template v-if="column.key === 'remainingAmount'">
                  <a style="color: #1ADB66">
                    {{ record.remainingAmount }}
                  </a>
                </template>
                <template v-if="column.key === 'totalAmount'">
                  <a style="color: #9C83C7">
                    {{ record.totalAmount }}
                  </a>
                </template>

                <template v-if="column.key === 'expirationTime'">
                  <a style="color: #a7a7ab">
                    {{ endDayFormat(record.expirationTime) }}
                  </a>
                </template>
                <template v-if="column.key === 'Action'">
                  <div @click="handggmbuy(record)" class="bg-[#9C83C7] rounded-md px-5 py-1 JungleAdventurer">
                    buy
                  </div>
                </template>

              </template>
            </a-table>
          </div>

        </div>
      </a-tab-pane>
      <a-tab-pane  key="3" tab="Buyback" disabled></a-tab-pane>
      <a-tab-pane  key="4" tab="History" >
        <div class="content w-full  mt-4 bg-[#5b4973] rounded-2xl" style="border: 1px solid rgba(255, 255, 255, 0.24);">
          <div class="top flex flex-row  p-3   items-center">
            <div class="lt rounded-full bg-[#1F1927] w-10 h-10 mr-4 flex items-center"  >
              <img width="85" src="../../assets/app/wallet/ggm.png">
            </div>
            <div class="gt flex flex-row justify-between bg-[#1F1927] flex-1 p-2.5 rounded-md items-center">
              <div class="yue inline-flex roboto">
                <div class="text-white ">GGM Balance:</div>
                <div style="color: #01bd35">{{ balanceFormat(GGM) }}</div>
              </div>
              <div @click="sellvisible=true" class="sell JungleAdventurer text-white rounded px-5 py-1 bg-[#9C83C7]" style="border: 1px solid rgba(255, 255, 255, 0.40);">
                sell
              </div>
            </div>
          </div>
          <div class="ct bg-[#1F1927] rounded-2xl m-3" style="overflow: hidden">
            <a-table class="m-2 "  :scroll="{x:true}"    :columns="historycolumns" :data-source="history_list">

              <template #bodyCell="{ column, record }">

                <template v-if="column.key === 'price'">
                  <a style="color: #a7a7ab">
                    {{ record.price }}
                  </a>
                </template>
                <template v-if="column.key === 'Turnover'">
                  <a style="color: #1ADB66">
                    {{ record.amount }}
                  </a>
                </template>
                <template v-if="column.key === 'sell>Buy'">
                  <a style="color: #9C83C7" class="flex flex-row items-center">
                    {{ record.from }}&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 12 16" fill="none">
                    <path d="M4.21002 1.85215L4.21002 9.68166L3.06444 8.50303C2.36072 7.77901 1.2315 7.77901 0.527787 8.50303C-0.175929 9.22705 -0.175929 10.3888 0.527787 11.1129L4.73372 15.457C5.43744 16.181 6.56666 16.181 7.27037 15.457L11.4763 11.1297C11.82 10.7761 12 10.2878 12 9.81636C12 9.34491 11.82 8.87345 11.4763 8.50303C10.7726 7.77901 9.64337 7.77901 8.93965 8.50303L7.79407 9.68166L7.79407 1.85215C7.79407 0.825048 6.99216 6.48037e-07 5.99386 6.91674e-07C4.99557 7.35311e-07 4.21002 0.825048 4.21002 1.85215Z" fill="#1ADB66"/>
                  </svg>
                  </a><br>
                  <a style="color: #9C83C7">
                    {{ record.to }}
                  </a>
                </template>

                <template v-if="column.key === 'Time'">
                  <a style="color: #a7a7ab">
                    {{ endDayFormat(record.createAt) }}
                  </a>
                </template>

              </template>
            </a-table>
          </div>

        </div>
      </a-tab-pane>
      <a-tab-pane  key="5" tab="Stake" disabled></a-tab-pane>
    </a-tabs>
<!--    <div class="w-96 pinzi grid grid-cols-5 gap-2 mt-4">-->
<!--      <div class="trade-item JungleAdventurer bg-local" @click="handtype(1)" :class="typ_active==1?'active':''"  >-->
<!--        Intro-->
<!--      </div>-->
<!--      <div class="trade-item JungleAdventurer" @click="handtype(2)" :class="typ_active==2?'active':''">-->
<!--        Trade-->
<!--      </div>-->
<!--      <div class="trade-item JungleAdventurer cursor-not-allowed" @click="handtype(3)" :class="typ_active==3?'active':''">-->
<!--        Buyback-->
<!--      </div>-->
<!--      <div class="trade-item JungleAdventurer" @click="handtype(4)" :class="typ_active==4?'active':''">-->
<!--        History-->
<!--      </div>-->
<!--      <div class="trade-item JungleAdventurer cursor-not-allowed" @click="handtype(5)" :class="typ_active==5?'active':''">-->
<!--        Stake-->
<!--      </div>-->

<!--    </div>-->
  </div>
  <a-modal width="400px"     destroyOnClose :closable="false"  :visible="buyggmvisible" :header-style="{display:'none'}" :footer="null" style="padding-bottom: 0;background: #5B4973;overflow: hidden;border-radius: 12px;box-shadow: rgba(0, 0, 0, 0.3) 0px 8px 8px, rgb(91, 73, 115) 0px 4px;" dialog-style="background: #5B4973"  body-style="background: #5B4973;padding:0;">
    <div class="flex flex-column align-items-center justify-content-center ">
      <div class="p-1 items-center text-center text-white text-xl JungleAdventurer  flex align-items-center" style="width: 100% ;color: #443862;border-bottom: 1px solid #9185a1;background: rgb(44,38,62);">

        <div class="flex-1 text-xl" style="color: rgb(156,131,199)">Buy Now</div>
        <img class="cursor-pointer" @click="buyggmvisible=false"  width="35" src="../../assets/web/getmoga/closebutton.png">
      </div>
    </div>
    <div class="space-y-8 p-4">

      <div class="flex space-x-4 ">
        <div class="flex flex-col items-center justify-center">
          <span class="text-white font-semibold w-16">Assets</span>
        </div>
        <a-card :loading="loading" class="ggm-card w-full " style="background: #1F1927;border: none">
          <div class="flex items-center space-x-2 ">
            <img src="../../assets/ggm_icon.png" class="w-8 h-8" />
            <div class="flex flex-col">
              <span class="text-white font-semibold">GGM</span>
              <!-- <div class="flex"> -->
              <span class="text-xs mr-1" style="color:#FBD940">{{balanceFormat(GGM)}}</span>
            </div>
          </div>
        </a-card>
      </div>

      <div class="flex space-x-4">
        <div class="flex flex-col items-center justify-center">
          <span class="text-white font-semibold w-16">Quantity</span>
        </div>
        <a-input-number @change="handggmchange" :min="1" style="width: 80%;background: #1f1927" class="a-input" v-model:value="ggmQuantity"  :controls="false"
                        >
          <template #addonAfter><span class="text-xs">GGM</span></template>
        </a-input-number>
        <div class="max-button cursor-pointer rounded-xl px-2 py-1" style="background-color: #01BD35;box-shadow:0px 4px 4px rgba(0,0,0,.3),0 2px rgba(4,184,53,1);" @click="handleMaxClick">
          MAX
        </div>
      </div>

      <div class="flex flex-col space-y-4">

        <div class="flex space-x-8 items-center">
          <span class="text-white w-16 font-semibold">Unit Price</span>
          <a-input disabled="" class="a-input bg-[#1f1927]" style="width: 242px;color: #01bd35" v-model:value="rowobj.price" placeholder="" />
        </div>
      </div>
      <div class="flex flex-col space-y-4">

        <div class="flex space-x-8 items-center">
          <span class="text-white w-16 font-semibold">Total Price</span>
          <a-input disabled="" class="a-input bg-[#1f1927]" style="width: 242px;color: #01bd35" v-model:value="ggmTotalPrice" placeholder="" />
        </div>
      </div>

      <a-alert v-if="isError" :message="isErrorMessage" type="error" />

      <div class="flex justify-center">
        <span style="background-color: #01BD35;box-shadow:0px 8px 8px rgba(0,0,0,.3),0 4px rgba(4,184,53,1);" class="text-xl next-button cursor-pointer JungleAdventurer p-2 w-96 text-center rounded-2xl" @click="handleBuyClick">BUY NOW</span>
      </div>

    </div>
  </a-modal>
  <a-modal   width="24rem"   destroyOnClose :closable="false"  :visible="sellvisible" :header-style="{display:'none'}" :footer="null" style="padding-bottom: 0;background: #5B4973;overflow: hidden;border-radius: 12px;box-shadow: rgba(0, 0, 0, 0.3) 0px 8px 8px, rgb(91, 73, 115) 0px 4px;" dialog-style="background: #5B4973"  body-style="background: #5B4973;padding:0;">
    <div class="flex flex-column align-items-center justify-content-center ">
      <div class="p-1 items-center text-center text-white text-xl JungleAdventurer  flex align-items-center" style="width: 100% ;color: #443862;border-bottom: 1px solid #9185a1;background: rgb(44,38,62);">

        <div class="flex-1 text-xl" style="color:#FBD940" >SELL</div>
        <img class="cursor-pointer" @click="sellvisible=false"  width="35" src="../../assets/web/getmoga/closebutton.png">
      </div>
    </div>

    <div class="space-y-8 p-4">

      <div class="flex space-x-4 ">
        <div class="flex flex-col items-center justify-center">
          <span class="text-white font-semibold w-16">Assets</span>
        </div>
        <a-card :loading="loading" class="ggm-card w-full " style="background: #1F1927;border: none">
          <div class="flex items-center space-x-2 ">
            <img src="../../assets/ggm_icon.png" class="w-8 h-8" />
            <div class="flex flex-col">
              <span class="text-white font-semibold">GGM</span>
              <!-- <div class="flex"> -->
              <span class="text-xs mr-1" style="color:#FBD940">{{balanceFormat(GGM)}}</span>
            </div>
          </div>
        </a-card>
      </div>

      <div class="flex space-x-4">
        <div class="flex flex-row items-center justify-center">
          <span class="text-white  font-semibold w-16">Quantity</span>
          <a-tooltip  title="The minimum amount of GGM to sell is 100GGM" :color="tipcolor">
            <img width="30" src="../../assets/app/wallet/tiphover.png">
          </a-tooltip>

        </div>
        <a-input-number :min="1" @focus="focus"  style="width: 80%;color: #01bd35" class="a-input" v-model:value="amountValue"  :controls="false"
                        >
          <template #addonAfter><span class="text-xs">GGM</span></template>
        </a-input-number>
        <div class="max-button cursor-pointer rounded-xl px-2 py-1" style="background-color: #01BD35;box-shadow:0px 4px 4px rgba(0,0,0,.3),0 2px rgba(4,184,53,1);" @click="handleMaxClick">
          MAX
        </div>
      </div>

      <div class="flex flex-col space-y-4">
        <!--        <span class="text-center text-xl font-semibold my-4">To</span>-->
        <div class="flex space-x-4">
          <div class="flex flex-row items-center justify-center">
            <span class="text-white w-13 font-semibold">Time</span>
            <a-tooltip  title="Trade time indicates the duration
of the listing. When listing
expires, unsold GGM will
be returned to your wallet." :color="tipcolor">
              <img width="30" src="../../assets/app/wallet/tiphover.png">
            </a-tooltip>
          </div>
          <a-select  style="" v-model:value="time" show-search class="w-60 a-select 1f1927" allowClear :options="timeoptions" :filterOption="false"
                    @search="myhandleSearch"
                    @change="myhandleChange" @focus="focus">
            <template #suffixIcon><caret-down-filled /></template>
          </a-select>
        </div>
        <div class="flex space-x-4 items-center">
          <span class="text-white w-16 font-semibold">Price</span>
          <a-input-number @focus="focus"  class="a-input bg-[#1f1927]" style="width: 237px;color: #01bd35" v-model:value="unitprice" placeholder="Price" />
        </div>
      </div>
      <div class="11 flex flex-row items-center justify-end">
        <span style="color: #BDB6C7">Trade Fee 5%</span>
        <a-tooltip  title="Trade time indicates the duration
of the listing. When listing
expires, unsold GGM will
be returned to your wallet." :color="tipcolor">
          <img width="30" src="../../assets/app/wallet/tiphover.png">
        </a-tooltip>
      </div>

      <a-alert v-if="isError" :message="isErrorMessage" type="error" />

      <div class="flex justify-center">
        <span style="background-color: #9C83C7;box-shadow:0px 8px 8px rgba(0,0,0,.3),0 4px rgba(156,131,199,1);" class="text-xl next-button cursor-pointer JungleAdventurer p-2 w-96 text-center rounded-2xl" @click="handleSendClick">Sell</span>
      </div>

    </div>
  </a-modal>
</template>

<script>
import TabbarLayout from "@/layouts/module/Tabbar.vue";
import WalletConnector from "@/wallet/components/WalletConnector";
import {selectDictLabel} from "@/utils/custom"
import {getCurrentInstance, h, ref} from 'vue';
import {ggmqueryOrders,Activities} from "@/api";
import { toFormatEthers } from "@/wallet/utils";
import {balanceFormat, endDayFormat, getTimestamp} from "@/utils";
import {message, Modal} from "ant-design-vue";
import {
  GET_ASSET721_BODY,
  getActionBodyCreateAsset20OrderBWO,
  sendActionCreateAsset20OrderBWO,
  getActionBodyAcceptAsset20OrderBWO,
  sendActionAcceptAsset20OrderBWO,
  getActionBodyBuyNFTBWO, sendActionBuyNFTBWO
} from "@/constants";
const tipcolor="#9C83C7"
const dictoptions=[
  {
    value: 1,
    label: 'rate'
  },
  {
    value: 2,
    label: 'rate'
  },
  {
    value: 3,
    label: 'rate'
  },
  {
    value: 4,
    label: 'rate'
  },
  {
    value: 5,
    label: 'rate'
  },
  {
    value: 6,
    label: 'rate'
  },
  {
    value: 7,
    label: 'rate'
  },
]
const columns = [{
  title: 'price',
  key: 'price',
}, {
  title: 'remaining Amount',
  key: 'remainingAmount',
}, {
  title: 'Total Supply',
  key: 'totalAmount',
}, {
  title: 'expirationTime',
  key: 'expirationTime',
}, {
  title: 'Action',
  key: 'Action',
  fixed: 'right',
}];
const historycolumns = [{
  title: 'price',
  key: 'price',
}, {
  title: 'Turnover',
  key: 'Turnover',
}, {
  title: 'sell>Buy',
  key: 'sell>Buy',
}, {
  title: 'Time',
  key: 'Time',
}];
const timeoptions=[
  {
    value: 1,
    label: '1H'
  },
  {
    value: 3,
    label: '3H'
  },
  {
    value: 6,
    label: '6H'
  },
  {
    value: 12,
    label: '12H'
  },
  {
    value: 24,
    label: '24H'
  },
]
import { reactive } from 'vue';
import {apiBridgev2} from "@/api/bridge";
const formState = reactive({
  username: '',
  password: '',
  remember: true,
});
const onFinish = values => {
  console.log('Success:', values);
};
const onFinishFailed = errorInfo => {
  console.log('Failed:', errorInfo);
};
export default {
  name: "wallet",
  components: {
    TabbarLayout,
  },
  setup(){
    const { proxy } = getCurrentInstance()
    let g=proxy.$g;
    if(sessionStorage.getItem('tid')===null||sessionStorage.getItem('tid')===''){
      g.login();
    }
    const buyggmvisible=ref(false)
    const sellvisible=ref(false)
    const unitprice=ref(2000)
    const amountValue=ref()
    let GGM=ref(0)
    const time=ref()

    const gcoinGgm= async () => {

      const t1 = new proxy.$accert.Token(proxy.$p, 'GGM');
      GGM.value = await t1.balanceOf(sessionStorage.getItem('tid'))||0;
      console.log(GGM.value,213)
    }
    gcoinGgm()
    const rowobj=ref()
    const handggmbuy=(row)=>{
      buyggmvisible.value=true
      rowobj.value=row
    }
    const handleMaxClick = async () => {
      amountValue.value = balanceFormat(
         GGM.value
      );
    };
    const changetab=(e)=>{
      console.log(e,689)
    }

    let typ_active=ref(1)
    let pagination={
      total: 0,
      pageSize: 10,//每页中显示10条数据
      showSizeChanger: false,
      pageSizeOptions: ["10", "20", "50", "100"],//每页中显示的数据
      // showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
    }
    let typ=ref('rate')
    const handtype = (e)=>{
      if(e==3||e==5){
        return
      }
      typ_active.value=e
      typ.value=selectDictLabel(dictoptions, e);
    }
    let orders_list = ref();
    let history_list = ref();
    ggmqueryOrders(1000,0,"CREATED",process.env.VUE_APP_pairId).then((res)=>{
      orders_list.value=res.data.orders
    })
    Activities(1000,0).then((res)=>{
      history_list.value=res.data.activities
      console.log(history_list.value,777)
    })

    const isError = ref(false)
    const isErrorMessage = ref("")
    const focus = () => {
      isError.value = false
    }


    // 挂单
    const handleSendClick = () => {
      if (amountValue.value == undefined) {
        isErrorMessage.value = "Amount is not null"
        isError.value = true
        return
      }
      if (time.value == undefined) {
        isErrorMessage.value = "Time is not null"
        isError.value = true
        return
      }
      if (unitprice.value == undefined) {
        isErrorMessage.value = "price is not null"
        isError.value = true
        return
      }

      const data = ref(getActionBodyCreateAsset20OrderBWO);
      data.value.id = getTimestamp();
      const params = [
        {

          "maker": parseInt(sessionStorage.getItem('tid')),  // 挂单账户
          "taker": 0,  // 买单账户，如果没有指定账户id，就是谁都可以买，0，代表谁都可以买
          "side": 1, // 0: buy 买单, 1: sell 卖单
          "tokenSale":"0xEeA9ca3094b5955dAC9451b8c288fE7d9268A53A",  // 出售的token的地址 比如ggm
          "tokenPrice":"0x96c48dD49Da50C92AA166d40BA787a1FCb78D2FD", // 定价的token的地址 比如gcion
          "totalAmount":amountValue.value.toString(), // 出售ggm的总量，单位是最小单位^10^18
          "price":unitprice.value.toString(), // 定价， 这里写2000 ，代表出售totalAmount 1000 ggm会 获得2000 gcion
          "expirationHours": time.value  // 订单过期时间
        }
      ];
      data.value.params = params;
      apiBridgev2(data.value).then((res) => {
        could_signTypedData(res);
      })
    };
    const could_signTypedData = async (typedData) => {
      const fromAccount = new proxy.$accert.Account(proxy.$p,sessionStorage.getItem('tid')*1);

      const t2 = new proxy.$accert.Token(proxy.$p, 'GGM');
      fromAccount.wallet = g; // 设置wallet提供签名功能
      const signature = await g.sign(typedData.result.typedData);
      console.log(signature,21354)
      try {

        const data = ref(sendActionCreateAsset20OrderBWO);
        data.value.id = getTimestamp();
        const params = [
          {
            "maker": parseInt(sessionStorage.getItem('tid')),  // 挂单账户
            "taker": 0,  // 买单账户，如果没有指定账户id，就是谁都可以买，0，代表谁都可以买
            "side": 1, // 0: buy 买单, 1: sell 卖单
            "tokenSale":"0xEeA9ca3094b5955dAC9451b8c288fE7d9268A53A",  // 出售的token的地址 比如ggm
            "tokenPrice":"0x96c48dD49Da50C92AA166d40BA787a1FCb78D2FD", // 定价的token的地址 比如gcion
            "totalAmount":amountValue.value.toString(), // 出售ggm的总量，单位是最小单位^10^18
            "price":unitprice.value.toString(), // 定价， 这里写2000 ，代表出售totalAmount 1000 ggm会 获得2000 gcion
            "expirationTime": typedData.result.expirationTime,  // 订单过期时间
            "salt":typedData.result.salt,
            "hash":typedData.result.hash,
            "signature": signature
          }
        ];
        data.value.params = params;
        apiBridgev2(data.value).then((res) => {
          if(res.error){
            message.error(res.error.message);
            return
          }
          message.success('Order successful')
          sellvisible.value=false
        })
      } catch(e){
        if (e instanceof proxy.$accert.ResponseError) {
          if (e.status === 0 && e.code === 1) {
            console.log('User cancelled, %o', e);
          } else {
            console.error('Response error, %o', e);
          }
        } else {
          console.error('Error, %o', e);
        }
      }
    }
    // 购买
    const ggmQuantity=ref()
    const ggmTotalPrice=ref()
    const pairId=process.env.VUE_APP_pairId
    const handleBuyClick = () => {
      if (ggmQuantity.value == undefined) {
        isErrorMessage.value = "Quantity is not null"
        isError.value = true
        return
      }

      const data = ref(getActionBodyAcceptAsset20OrderBWO);
      data.value.id = getTimestamp();
      const params = [
        {
          "pair": pairId, // pair 地址。ggm-gcion
          "taker": parseInt(sessionStorage.getItem('tid')), // 买单账户
          "listingOrderHash":rowobj.value.id, // 订单hash
          "value":ggmQuantity.value.toString(), // 购买总量
          "sender":sessionStorage.getItem('cloud_wallet') // taker 签名地址
        }
      ];
      data.value.params = params;
      apiBridgev2(data.value).then((res) => {
        buycould_signTypedData(res);
      })
    };
    const buycould_signTypedData = async (typedData) => {
      const fromAccount = new proxy.$accert.Account(proxy.$p,sessionStorage.getItem('tid')*1);

      const t2 = new proxy.$accert.Token(proxy.$p, 'GGM');
      fromAccount.wallet = g; // 设置wallet提供签名功能
      const signature = await g.sign(typedData.result.typedData);
      console.log(signature,21354)
      try {

        const data = ref(sendActionAcceptAsset20OrderBWO);
        data.value.id = getTimestamp();
        const params = [
          {
            "pair": pairId, // pair 地址。ggm-gcion
            "taker":parseInt(sessionStorage.getItem('tid')),  // 买单账户
            "listingOrderHash": rowobj.value.id, // 订单hash
            "value":ggmQuantity.value.toString(), // 购买总量
            "sender":sessionStorage.getItem('cloud_wallet'), // taker 签名地址
            "hash": typedData.result.hash,
            "signature":signature
          }
        ];
        data.value.params = params;
        apiBridgev2(data.value).then((res) => {
          if(res.error){
            message.error(res.error.message);
            return
          }
          message.success('Buy successful')
          buyggmvisible.value=false
        })
      } catch(e){
        if (e instanceof proxy.$accert.ResponseError) {
          if (e.status === 0 && e.code === 1) {
            console.log('User cancelled, %o', e);
          } else {
            console.error('Response error, %o', e);
          }
        } else {
          console.error('Error, %o', e);
        }
      }
    }
    const handggmchange=(e)=>{
        ggmTotalPrice.value=(rowobj.value).price*e
    }
    return{
      typ_active,
      handtype,
      pagination: pagination,
      typ,
      activeKey: ref('2'),
      history_list,
      orders_list,
      columns,
      historycolumns,
      endDayFormat,
      buyggmvisible,
      sellvisible,
      timeoptions,
      tipcolor,
      unitprice,
      time,
      handggmbuy,
      GGM,
      amountValue,
      toFormatEthers,
      balanceFormat,
      handleMaxClick,
      handleSendClick,
      isError,
      isErrorMessage,
      formState,
      focus,

      ggmQuantity,
      ggmTotalPrice,
      handleBuyClick,
      rowobj,
      handggmchange


    }
  },
}
</script>

<style scoped>
.pinzi{
  background: linear-gradient(0deg, #5B4973 0%, rgba(91, 73, 115, 0.00) 100%);
  border-radius: 12px;
  border: 2px solid #5B4973;
  padding: 5px 10px;
  box-shadow: 0px  3px  #40314c;
}
.trade-item{
  border-radius:.5rem;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: linear-gradient(0deg, rgba(17, 23, 45, 0.68) 0%, rgba(17, 23, 45, 0.00) 100%), #333549;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  height: 42px;
}
.trade-item.active{
  border: 1px solid rgba(255, 255, 255, 0.02);
  background: #413767;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.40);
  color: #fff;
}
.pinzi-item img{
  height: 70%;
}
.itembg{
  background-image: url("../../assets/app/wallet/itembg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /*aspect-ratio: 324 / 392;*/
  aspect-ratio:265/333;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*::v-deep .ant-tabs-nav-list{background-color: #323447;border-radius: 12px 12px 0 0;margin: auto;text-align: center;overflow: hidden;*/
/*  border: 1px solid #3f4154}*/
::v-deep .ant-tabs-tab{margin: 0;font-family: "Jungle Adventurer";color: #797a87;
  border-radius:.5rem;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: linear-gradient(0deg, rgba(17, 23, 45, 0.68) 0%, rgba(17, 23, 45, 0.00) 100%), #333549;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  height: 42px;
}
::v-deep .ant-tabs-ink-bar{
  background: transparent;
}
::v-deep .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #ffffff;

  /*text-shadow: 0 0 5px #ffffff*/

}
::v-deep .ant-tabs-tab-active{
  border: 1px solid rgba(255, 255, 255, 0.02);
  background: #413767;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.40);
  color: #fff;

}
::v-deep .ant-tabs-nav-list{
  width: 100%;
  background: linear-gradient(0deg, #5B4973 0%, rgba(91, 73, 115, 0.00) 100%);
  border-radius: 12px;
  border: 2px solid #5B4973;
  padding: 5px 10px;
  box-shadow: 0px  3px  #40314c;
  display: grid !important;
  grid-template-columns: repeat(5,minmax(0, 1fr));
  gap: 0.5rem;
}
::v-deep .ant-tabs .ant-tabs-nav-wrap{
  overflow: unset !important;
}


::v-deep .ant-table{background: transparent;color: #fff;
  border-radius: 10px; }
::v-deep .ant-table-thead > tr > th{background: #1B203E;color: #D8F2FA;border: none;font-family: "Jungle Adventurer"}
::v-deep .ant-table-tbody > tr > td{border: none}
::v-deep .ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;

}
::v-deep .ant-table-cell{
  border-bottom: 10px solid #161626 !important;
}
::v-deep .ant-table-row{border-radius: 12px !important;border: 2px solid #ffffff;background-color: #2A243A ;
}
::v-deep .ant-table-cell-fix-right{
  background: none;

}


::v-deep .ant-table-tbody > tr >td.ant-table-cell-row-hover{
  background: none !important;
}
::v-deep .ant-pagination{
  display: table;background: #1B203E;padding: 10px 20px;border-radius: 10px;color: #fff;margin: 16px auto;
}
::v-deep .ant-pagination-item{
  background-color: transparent;border: none;
}
::v-deep .ant-pagination-item-link{
  background: none;border: none;color: #fff;
}
::v-deep .ant-pagination-item-active{
  background: #5C5D8E;border: none;
}
::v-deep .ant-pagination-item-active a{
  color: #0A0048;
}
::v-deep .ant-pagination-item a{color: #fff}
/*滚动条*/
/*::v-deep .ant-table-content::-webkit-scrollbar {*/
/*  width: 6px;*/
/*  height: 6px;*/
/*}*/
/*::v-deep .ant-table-content::-webkit-scrollbar-track {*/
/*  border-radius: 3px;*/
/*  background: rgba(0,0,0,0.06);*/
/*  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.08);*/
/*}*/
/*!* 滚动条滑块 *!*/
/*::v-deep .ant-table-content::-webkit-scrollbar-thumb {*/
/*  border-radius: 3px;*/
/*  background: rgba(91,73,115,1);*/
/*  -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,0.5);*/
/*}*/
</style>
