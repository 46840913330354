
import { defineComponent, ref, onMounted, onActivated, watchEffect } from "vue";

import Assets from "@/components/card/assets.vue";
import Account from "@/components/card/account.vue";
import NoData from "@/components/common/noData.vue";
import TabbarLayout from "@/layouts/module/Tabbar.vue";

import { Transaction_Type } from "@/constants/index";

import { useRouter, useRoute } from "vue-router";

import { queryAssetActions, queryAccountActions } from "@/api";

export default defineComponent({
  components: {
    Assets,
    Account,
    NoData,
    TabbarLayout
  },
  setup() {
    const assetPage = ref(1);

    let urlMapping = new Map();
    let nameMapping = new Map();
    let logoUrlMapping = new Map();

    const assetActions = ref();
    const getAssetActions = async (page: number) => {
      queryAssetActions(page).then((res) => {
        assetActions.value = res?.data?.account?.assetActions;
        assetActions.value.forEach((element) => {

          element.nft.metadatas.forEach((data) => {
            if (data.key == "image_url") {
              urlMapping.set(element.nft.id, data.value);
            } else if (data.key == "name") {
              nameMapping.set(element.nft.id, data.value);
            } else if (data.key == "logo_url") {
              logoUrlMapping.set(element.nft.id, data.value);
            }
          });
        });
        busy.value = false
      });
    };

    getAssetActions(assetPage.value);

    const activeKey = ref("1");

    const busy = ref(false)

    const loadMore = () => {
      busy.value = true;
      getAssetActions(assetPage.value++);
    };

    const router = useRouter();
    const changeTabs = () => {
      router.push({
        name: "accountActivity",
      });
    };

    return {
      Transaction_Type,
      activeKey,
      assetActions,
      urlMapping,
      logoUrlMapping,
      nameMapping,
      busy,
      changeTabs,
      loadMore,
    };
  },
});
