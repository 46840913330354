<template>
  <div class="main  flex flex-col items-center justify-center mx-auto mt-2" >
    <div class="w-96">
      <TabbarLayout></TabbarLayout>
    </div>
    <GGM class="w-96"
        @eventSendTo="childClick"
        @eventCrossChainSend="childClick"
        :is-me="true"
        :account-id="cardAccountId"
    ></GGM>
    <MogaNFT class="w-96"
        title="Moga"
        @event="openNFTDetailsDrawer"
        :type="NFT_Type.MOGA"
        :account-id="cardAccountId"
    ></MogaNFT>
    <MogaNFT class="w-96"
        title="Equip"
        @event="openNFTDetailsDrawer"
        :type="NFT_Type.EQPT"
        :account-id="cardAccountId"
    ></MogaNFT>


  </div>
  <GgmSendTokenModal
      :visible="visibleGgmSendTokenModal"
      @eventRefresh="closeGgmSendTokenDrawerAndRefresh"
      @close="hideGgmSendTokenModal"
  ></GgmSendTokenModal>
  <GcoinSendTokenModal
      :visible="visibleGcoinSendTokenModal"
      @eventRefresh="closeGcoinSendTokenDrawerAndRefresh"
      @close="hideGcoinSendTokenModal"
  ></GcoinSendTokenModal>
  <a-modal   :closable="false"  v-model:visible="okvisible" :header-style="{display:'none'}" :footer="null" style="padding-bottom: 0;background: #5B4973;overflow: hidden;border-radius: 12px" dialog-style="background: #5B4973"  body-style="background: #5B4973;padding:0;">
    <div class="flex flex-column align-items-center justify-content-center" style="flex-direction: column">
      <div class="text-center text-white text-xl JungleAdventurer p-2 flex align-items-center" style="width: 100% ;color: #443862;border-bottom: 1px solid #9185a1;background: rgb(44,38,62);">

        <div class="flex-1 text-xl" style="color: rgb(156,131,199)">successful  Transfer</div>
        <img class="cursor-pointer" @click="okclose" width="35" src="../../assets/web/getmoga/closebutton.png">
      </div>
      <div class="p-2 flex-col flex flex-column items-center justify-center">
        <div ><img  src="../../assets/app/wallet/sucess.png" alt="" class="avatar w-72" /></div>
        <div class="text-xl my-4" style="color: #24FF7A">Transaction Submited</div>
        <router-link  to="/activity" style="color: #1ADB6680;fill: #342942;opacity: 0.67;box-shadow: 0px 0px 46px 0px #000 inset;" @click="okclose" class="cursor-pointer  text-xl px-5 py-1 rounded-md my-4"
           >View in Recent Activities</router-link>
        <div @click="okclose" class="JungleAdventurer text-white text-2xl w-full text-center bg-[#9C83C7] rounded-lg py-2 mb-2" style="box-shadow: 0 3px #7D68A1;
filter: drop-shadow(2.9000000953674316px 13.699999809265137px 38px rgba(0, 0, 0, 0.43));">
          Close
        </div>
      </div>

    </div>

  </a-modal>
</template>

<script>
import TabbarLayout from "@/layouts/module/Tabbar.vue";
import GgmSendTokenModal from "@/components/modal/GgmSendToken.vue";
import GcoinSendTokenModal from "@/components/modal/GcoinSendToken.vue";
import GgmSendTokenDrawer from "@/components/drawer/GgmSendToken.vue";
import WalletConnector from "@/wallet/components/WalletConnector";
import {useToggleGgmSendTokenModal,useToggleGcoinSendTokenModal} from "@/hooks/useToggleWithdraw";
import {defineComponent, getCurrentInstance, ref} from "vue";
import GGM from "@/components/card/ggm.vue";
import MogaNFT from "@/components/card/nft.vue";
import { NFT_Type, Assets_Type } from "@/constants";
import {accountStore} from "@/store";
import {storeToRefs} from "pinia";
const options=[
    {lable:1,value:1},
  {lable:1,value:1},
  {lable:1,value:1},
  {lable:1,value:1},
  ]
export default defineComponent({
  components: {
    TabbarLayout,
    GgmSendTokenModal,
    GgmSendTokenDrawer,
    GcoinSendTokenModal,
    GGM,
    MogaNFT
  },
  setup(){
    const { proxy } = getCurrentInstance()
    let g=proxy.$g;
    if(sessionStorage.getItem('tid')===null||sessionStorage.getItem('tid')===''){
      g.login();
    }
    const okvisible=ref(false)
    const closeGgmSendTokenDrawerAndRefresh = async () => {
      hideGgmSendTokenModal();
      okvisible.value=true
    };
    const closeGcoinSendTokenDrawerAndRefresh = async () => {
      hideGgmSendTokenModal();
      okvisible.value=true

    };
    const okclose= async () => {
      okvisible.value=false
      setTimeout(function () {
        location.reload();
      }, 2000);
    }
    const {
      visibleGgmSendTokenModal,
      showGgmSendTokenModal,
      hideGgmSendTokenModal,
    } = useToggleGgmSendTokenModal();
    const {
      visibleGcoinSendTokenModal,
      showGcoinSendTokenModal,
      hideGcoinSendTokenModal,
    } = useToggleGcoinSendTokenModal();
    //wang
    let childClick = (e) => {
      if(e==0){
        showGcoinSendTokenModal()
      }else if(e==1){
        showGgmSendTokenModal()
      }else if(e==2){
        showGgmSendTokenModal()
      }else if(e=='trade'){
        // router.push('/trade')
      }
    };

    const store = accountStore();
    const { getAId } = storeToRefs(store);
    const cardAccountId =
        getAId.value == undefined || getAId.value == ""
            ? ref(-1)
            : ref(Number(getAId.value));
    return{
      visibleGgmSendTokenModal,
      showGgmSendTokenModal,
      hideGgmSendTokenModal,
      closeGgmSendTokenDrawerAndRefresh,
      visibleGcoinSendTokenModal,
      showGcoinSendTokenModal,
      hideGcoinSendTokenModal,
      closeGcoinSendTokenDrawerAndRefresh,
      childClick,
      NFT_Type,
      Assets_Type,
      cardAccountId,
      okvisible,
      okclose
    }
  },
})
</script>

<style scoped>
.pinzi{
  background: linear-gradient(0deg, #5B4973 0%, rgba(91, 73, 115, 0.00) 100%);
  border-radius: 12px;
  border: 2px solid #5B4973;
  padding: 5px 10px;
  box-shadow: 0px  3px  #40314c;
}
.pinzi-item{
  background-image: url("../../assets/app/wallet/pinzi.png");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pinzi-item.active{
  box-shadow: 0 0 0 .5px #38393e,0 0 0 1px #ae90d6,0 0 0 1.5px #675381;
  border-radius: 50%;
    /*background-image: url("../../assets/app/wallet/pinziactive.png");*/
    /*background-size: cover;*/
    /*background-position: center;*/
}
.pinzi-item img{
  height: 70%;
}
.itembg{
  background-image: url("../../assets/app/wallet/itembg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  aspect-ratio: 324 / 392;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
