import Axios, { AxiosInstance } from "axios";

export type APIParams = Record<string, string | number | null | undefined>;

export class APIRequester {
  private axios: AxiosInstance;

  constructor(baseURL: string) {
    this.axios = Axios.create({
      baseURL,
      headers: {
        Accept: "application/json",
      },
      timeout: 30000,
    });
  }

  public async getRaw<T>(
    endpoint: string,
    params: URLSearchParams | APIParams = {}
  ): Promise<T> {
    return this.axios.get(endpoint, { params }).then((d) => d.data);
  }

  public async get<T>(
    endpoint: string,
    params: URLSearchParams | APIParams = {}
  ): Promise<T> {
    return this.axios.get(endpoint, { params }).then((d) => d.data);
  }

  public async postRaw<T>(endpoint: string, data?: any): Promise<T> {
    return this.axios.post(endpoint, data).then((d) => d.data);
  }

  public async post<T>(endpoint: string, data?: any): Promise<T> {
    return this.axios.post(endpoint, data).then((d) => d.data);
  }
}

export const request = new APIRequester(process.env.VUE_APP_BASE_API as string);
export const ggmrequest = new APIRequester(process.env.VUE_APP_GGM_BASE_URL as string);
