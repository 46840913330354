import { request } from "@/wallet/utils";

export async function apiGetBody(data: any): Promise<any> {
  return request.post<any>(`/operatorV2main`, data);
}

export async function apiOperator(data: any): Promise<any> {
  return request.post<any>(`/operatorV2main`, data);
}
export async function couldapiOperator(data: any): Promise<any> {
  return request.post<any>(``, data);
}

