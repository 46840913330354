
const timearr=[
  {time:'2023-09',desc:'Tournament Season 0 - World Boss Challenge. Genesis Moga Sale: Peepee, Lumo, Mr. Nimbus.'},
  {time:'2023-08',desc:'Launch of Moga Arcade, a Web3 platform based on MonsterGalaxy IP & powered by $GGM with the perfect casual gaming experience for players of all skills!'},
  {time:'2023-07',desc:'New website UI and Genesis Moga Sale: Anubis, Blackgold, and Albion. Gacha system update.'},
  {time:'2023-06',desc:'GGM cross-bridge function goes live on website. Players can transfer GGM from GON to BSC via Moga ID.'},
  {time:'2023-05',desc:'Moga ID & GCoin smart contracts pass the Certik audit.'},
  {time:'2023-05',desc:'Guilds / Special Arena / Blindbox available in game.'},
  {time:'2023-05',desc:'MOGA ID Beta release. MOGA ID serves as the player’s ID portal, from which they can manage their game devices, wallets, and virtual assets. Players will also be able to send and receive crypto tokens and NFTs as well as view their previous activity'},
  {time:'2023-04',desc:'Token burn. We immediately burnt the 655 million remaining unvested GGM tokens. There are 345 million GGM tokens outstanding.'},
  {time:'2023-03',desc:'“Buy GCoin" function added to website.'},
  {time:'2023-03',desc:'Launch of Tokenomics Evolution, designed to restructure the functions and utility of in-game tokens to further stabilize players’ earnings.'},
  {time:'2023-01',desc:'100% USD-backed stablecoin GCoin is introduced and officially becomes the sole token used in all Marketplace transactions in order to stabilize the price of NFTs and game items on the Marketplace'},
  {time:'2022-11',desc:'Release of Wiki & FAQ'},
  {time:'2022-10',desc:'New website with enhanced user interface goes live.'},
  {time:'2022-10',desc:'MOGA P2E Open Beta launches.'},
]
import { ClockCircleOutlined } from '@ant-design/icons-vue';
export default {
name: "index",
  components:{
    ClockCircleOutlined,
  },
  setup(){
  return{
    timearr
  }
  }
}
