export enum Transaction_Type {
  Transfer = "AssetTransfer",
}

export enum Assets_Type {
  Token = 0,
  NFT = 1,
}

export enum NFT_Type {
  MOGA = "Moga",
  EQPT = "Equip",
}

export enum Symbol_Type {
  MOGA = "MOGA",
}

export enum User_Type {
  Select = "Select",
  Close = "Close",
}

// 判断类型 用于Acount页面
export const METAVERSE_TYPE = "0x375e556559b149ea7254c9616d624e6b9f3b66b3";

export const SYMBOL_GGM = "GCOIN";

export const PAGE_SIZE = 10;

export const GONSCAN_URL = "https://www.gonscan.com/tx/";
export const GONSCAN_ACTIONS_URL = "https://www.gonscan.com/actions/";
export const GONSCAN_TX_URL = "https://www.gonscan.com/tx/";
export const GONSCAN_ADDRESS_URL = "https://www.gonscan.com/address/";

export const MANAGER = process.env.VUE_APP_GON_MANAGER_CONTRACT?.toLocaleLowerCase() as string
export const TOKEN = process.env.VUE_APP_GCOIN_CONTRACT?.toLocaleLowerCase() as string
//wang
export const GGMTOKEN = process.env.VUE_APP_GGM_CONTRACT?.toLocaleLowerCase() as string

export const MARKETPLACE_MOGA_URL =
  "https://marketplace.playmonstergalaxy.com/assets/moga";
export const MARKETPLACE_EQUIPMENT_URL =
  "https://marketplace.playmonstergalaxy.com/assets/equipment";

import { ref } from "vue";

export const ASSETS_LIST = ref([
  {
    value: "Moga",
    label: "Moga",
  },
  {
    value: "Equip",
    label: "Equip",
  },
]);

export const CHAIN_LIST = ref([
  {
    value: "Moga",
    label: "Moga",
  },
  {
    value: "Equip",
    label: "Equip",
  },
]);

export interface IUser {
  accountId: string;
  address: string;
  type: number;
}

export interface IUsers {
  accountId: string;
  address: string;
}

export interface IOptionUser {
  label: string;
  value: string;
}

export const DEFAULT_IMG = "/assets/default.png";

export const GET_ASSET721_BODY = {
  jsonrpc: "2.0",
  method: "operator_getAsset721TransferFromBWOBody",
  params: [
    {
      sender: "",
      contractAddr: "",
      fromId: 0,
      toId: 0,
      tokenId: "0",
      deadline: 0,
    },
  ],
  id: 1,
};

export const GET_ASSET20_BODY = {
  jsonrpc: "2.0",
  method: "operator_getAsset20TransferFromBWOBody",
  params: [
    {
      sender: "",
      contractAddr: "",
      fromId: 0,
      toId: 0,
      amount: "0",
      deadline: 0,
    },
  ],
  id: 1,
};

export const ASSET721_TRANSFER = {
  jsonrpc: "2.0",
  method: "operator_asset721TransferFromBWO",
  params: [
    {
      sender: "",
      contractAddr: "",
      fromId: 0,
      tokenId: "0",
      deadline: 0,
      hash: "",
      signature: "",
    },
  ],
  id: 1,
};

export const ASSET20_TRANSFER = {
  jsonrpc: "2.0",
  method: "operator_asset20TransferFromBWO",
  params: [
    {
      sender: "",
      contractAddr: "",
      fromId: 0,
      amount: "0",
      deadline: 0,
      hash: "",
      signature: "",
    },
  ],
  id: 1,
};

export const GET_BRIDGEINFO = {
  jsonrpc: "2.0",
  method: "bridge_getBridgeInfo",
  params: [],
  id: 1
}

export const GET_CROSSCHAIN_ERC20_TRANSFER_BODY = {
  jsonrpc: "2.0",
  method: "bridge_getCrossChainERC20TransferBWOBody",
  params: [
    {
      manager: "",
      token: "",
      sender: "",
      receiver: "",
      fromId: 0,
      toId: 0,
      amount: "0",
      disChainId: 0,
      deadline: 0
    }
  ],
  id: 1
};

export const ERC20_CROSSCHAIN_TRANSFER = {
  jsonrpc: "2.0",
  method: "bridge_crossChainERC20TransferBWO",
  params: [
    {
      manager: "",
      token: "",
      sender: "",
      receiver: "",
      fromId: 0,
      toId: 0,
      amount: "0",
      disChainId: 0,
      deadline: 0,
      hash: "",
      signature: ""
    },
  ],
  id: 1,
};

export const GET_CROSSCHAIN_ERC721_TRANSFER_BODY = {
  jsonrpc: "2.0",
  method: "bridge_getCrossChainERC721TransferBWOBody",
  params: [
    {
      manager: "",
      nft: "",
      sender: "",
      receiver: "",
      fromId: 0,
      toId: 0,
      tokenId: "0",
      disChainId: 0,
      deadline: 0
    }
  ],
  id: 1
};

export const ERC721_CROSSCHAIN_TRANSFER = {
  jsonrpc: "2.0",
  method: "bridge_crossChainERC721TransferBWO",
  params: [
    {
      manager: "",
      nft: "",
      sender: "",
      receiver: "",
      fromId: 0,
      toId: 0,
      tokenId: "0",
      disChainId: 0,
      deadline: 0,
      hash: "",
      signature: ""
    },
  ],
  id: 1,
};
export const isTrustWorld = {
  jsonrpc: "2.0",
  method: "operator_isTrustWorld",
  params: [
    {
      id: 11334,
      world: "0x972A2467AB4C92a39ce4476718b3006a2EE6Cb81"
    },
  ],
  id: 1,
};
export const getActionBodyTrustWorldBWO = {
  jsonrpc: "2.0",
  method: "operator_getActionBodyTrustWorldBWO",
  params: [
    {
      sender: "",// 签名的账户地址
      world: "0x972A2467AB4C92a39ce4476718b3006a2EE6Cb81",// world合约地址
      deadline: 1685964125 ,
      isTrustWorld: true
    },
  ],
  id: 1,
};
export const sendActionTrustWorldBWO = {
  jsonrpc: "2.0",
  method: "operator_sendActionTrustWorldBWO",
  params: [
    {
      sender: "",// 签名的账户地址
      world: "0x972A2467AB4C92a39ce4476718b3006a2EE6Cb81",// world合约地址
      deadline: 0 ,
      isTrustWorld: true,
      hash:"",
      signature: ""
    },
  ],
  id: 1,
};
export const getActionBodyBuyNFTBWO = {
  jsonrpc: "2.0",
  method: "operator_getActionBodyBuyNFTBWO",
  params: [
    {
      buyer: "",
      tokenId: "0",
      deadline:0
    },
  ],
  id: 1,
};
export const sendActionBuyNFTBWO = {
  jsonrpc: "2.0",
  method: "operator_sendActionBuyNFTBWO",
  params: [
    {
      buyer: "",
      tokenId: "0",
      deadline:0,
      hash:"",
      signature: ""
    },
  ],
  id: 1,
};
// buyggm
export const getActionBodyCreateAsset20OrderBWO = {
  jsonrpc: "2.0",
  method: "operator_getActionBodyCreateAsset20OrderBWO",
  params: [
    {
      "maker": 11334,  // 挂单账户
      "taker": 0,  // 买单账户，如果没有指定账户id，就是谁都可以买，0，代表谁都可以买
      "side": 1, // 0: buy 买单, 1: sell 卖单
      "tokenSale":"0xEeA9ca3094b5955dAC9451b8c288fE7d9268A53A",  // 出售的token的地址 比如ggm
      "tokenPrice":"0x96c48dD49Da50C92AA166d40BA787a1FCb78D2FD", // 定价的token的地址 比如gcion
      "totalAmount":"10000000000000000000", // 出售ggm的总量，单位是最小单位^10^18
      "price":"", // 定价， 这里写2000 ，代表出售totalAmount 1000 ggm会 获得2000 gcion
      "expirationHours": 1696333802  // 订单过期时间
    }
  ],
  id: 1,
};
export const sendActionCreateAsset20OrderBWO = {
  jsonrpc: "2.0",
  method: "operator_sendActionCreateAsset20OrderBWO",
  params: [
    {
      "maker": 11334,  // 挂单账户
      "taker": 0,  // 买单账户，如果没有指定账户id，就是谁都可以买，0，代表谁都可以买
      "side": 1, // 0: buy 买单, 1: sell 卖单
      "tokenSale":"0xEeA9ca3094b5955dAC9451b8c288fE7d9268A53A",  // 出售的token的地址 比如ggm
      "tokenPrice":"0x96c48dD49Da50C92AA166d40BA787a1FCb78D2FD", // 定价的token的地址 比如gcion
      "totalAmount":"10000000000000000000", // 出售ggm的总量，单位是最小单位^10^18
      "price":"15000000000000000000", // 定价， 这里写2000 ，代表出售totalAmount 1000 ggm会 获得2000 gcion
      "expirationTime": 1696333802,  // 订单过期时间
      "salt":"61960964383592449",
      "hash":"0x453199fe1e9aa9324494d14ebaaa442095af6cc726984016ead1da84ba0e51dc",
      "signature": "0xb23b524a1615877f713ebd506eb54dd9fe3dc852ae3b389b723ef637ab65eeb97350075886522dc4b52a9f6123509a9a006516cde0deb8dd97afb885947ee5f61b"
    }
  ],
  id: 1,
};
export const getActionBodyAcceptAsset20OrderBWO = {
  jsonrpc: "2.0",
  method: "operator_getActionBodyAcceptAsset20OrderBWO",
  params: [
    {
      "pair": "0x124955b92e199cd92f26a4dabd2a918f29fabcb8", // pair 地址。ggm-gcion
      "taker": 11336,  // 买单账户
      "listingOrderHash": "0x4e1714c69ce364ab3d8c85aac4e37152bd067fd4f9fa4ddb1f5a463201f74deb", // 订单hash
      "value":"2000", // 购买总量
      "sender":"0x1baf38fc8501546fB3598F0530E8C2d2dcC4EfD0" // taker 签名地址
    }
  ],
  id: 1,
};
export const sendActionAcceptAsset20OrderBWO = {
  jsonrpc: "2.0",
  method: "operator_sendActionAcceptAsset20OrderBWO",
  params: [
    {
      "pair": "0x124955b92e199cd92f26a4dabd2a918f29fabcb8", // pair 地址。ggm-gcion
      "taker": 11336,  // 买单账户
      "listingOrderHash": "0x4e1714c69ce364ab3d8c85aac4e37152bd067fd4f9fa4ddb1f5a463201f74deb", // 订单hash
      "value":"2000", // 购买总量
      "sender":"0x1baf38fc8501546fB3598F0530E8C2d2dcC4EfD0", // taker 签名地址
      "hash": "0xaf7ab10e725590abfd8de3c3736430bec24aa6262c4407942445923551acf5bf",
      "signature": "0x155afc9add33926520c13bd0d88821227dc369bb57bf5c2e85aea21726584c757dd6abf12c404e055ef391e69c7947d1f7802d45126f09a41331c2295571455b1c"
    }
  ],
  id: 1,
};
