
  import AOS from 'aos'
  import 'aos/dist/aos.css'
  import ContentLayout from "@/layouts/module/Content.vue";
  import { defineComponent, reactive, toRefs, watch,ref,Ref,onMounted,onUnmounted  } from 'vue';
  import { isMobile } from "@/utils";

  import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    PieChartOutlined,
    MailOutlined,
    DesktopOutlined,
    InboxOutlined,
    AppstoreOutlined,
  } from '@ant-design/icons-vue';
  import {message} from "ant-design-vue";
  import axios from "axios";
  interface DragData {
    isDragging: boolean;
    startX: number;
    startY: number;
    left: number;
    top: number;
  }
  export default defineComponent({
    components: {
      MenuFoldOutlined,
      MenuUnfoldOutlined,
      PieChartOutlined,
      MailOutlined,
      DesktopOutlined,
      InboxOutlined,
      AppstoreOutlined,
    },
    data() {
      return {
        email:'',
        issucesss:0,
      };
    },
    methods:{
      handissucesss(){
        this.issucesss=0
      },
      subscribeEmail(){
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        console.log(this.email.match(emailRegex),79)
        if(this.email.match(emailRegex)==null){
          message.error({
            content: () => 'Invalid Email',
            class: 'custom-class',
            icon:' ⚠',
          })
          return
        }
        const cd1api = process.env.VUE_APP_CD_API;
        axios.post(cd1api + 'mogasubscribe', {email:this.email,id:2},{headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
            .then((res) => {
              let data=res.data
              if(data.status==100){
                this.isSubscription=false
                this.issucesss=1
                this.email=''
                message.success({
                  content: () => data.message,
                  class: 'custom-class',
                  icon:' ',
                })
              }else{
                message.error({
                  content: () => data.message,
                  class: 'custom-class',
                  icon: ' ',
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      },
    },
    setup() {
      AOS.init()
      const state = reactive({
        collapsed: false,
        selectedKeys: ['1'],
        openKeys: ['sub1'],
        preOpenKeys: ['sub1'],
      });


      const jh= (t) => {
        const sElement= document.getElementsByClassName('jh')
        for (let i = 0; i < sElement.length; i++) {
          if (i==t){
            sElement[i].setAttribute('style', 'opacity:1');
          }else{
            sElement[i].setAttribute('style', 'opacity:0.7');
          }
        }
      }
      const jhl= () => {
        for (const sElement of document.getElementsByClassName('jh')) {
          sElement.setAttribute('style', 'opacity:0.7');
        };
        document.getElementById('jh')?.setAttribute('style', 'opacity:1');
      }


      const visible = ref<boolean>(false);
      const loginVisible= ref<boolean>(false);
      const isOpen = ref<boolean>(false);
      const isSubscription=ref<boolean>(false);

      const showModal = () => {
        visible.value = true;
      };
      const showloginmodal= () => {
        loginVisible.value=true
      }
        const handopen = () => {
          console.log(7789)
        isOpen.value=true
      }

      const handleOk = (e: MouseEvent) => {
        console.log(e);
        visible.value = false;
      };

      const handleClose = (e: MouseEvent) => {
        visible.value = false;
      };
      const ggmvisible = ref<boolean>(false);
      const ggmvisiblehandleOk = (e: MouseEvent) => {
        console.log(e);
        ggmvisible.value = true;
      };
      const ggmvisiblehandleClose = (e: MouseEvent) => {
        ggmvisible.value = false;
      };

      const videoVisible= ref<boolean>(false);
      const showvideomodal= () => {
        videoVisible.value=!videoVisible.value
      }
      const  closeSubscription= () => {
        isSubscription.value=false
      }

      const isJoin= ref<boolean>(false);
      if (!sessionStorage.getItem('tid')&&!localStorage.getItem('isS')){
        setTimeout(()=>{
          isSubscription.value=true
          localStorage.setItem('isS','1')
        },10000)
      }



      watch(
          () => state.openKeys,
          (_val, oldVal) => {
            state.preOpenKeys = oldVal;
          },
      );
      const toggleCollapsed = () => {
        state.collapsed = !state.collapsed;
        state.openKeys = state.collapsed ? [] : state.preOpenKeys;
      };
      const audio = ref<HTMLAudioElement | null>(null);
      let isPlay = ref(false); //当前音频是否在播放
      const handlePlayMusic = () => {
        let dom = audio.value||null;
        isPlay.value=!isPlay.value
        if(isPlay.value==true){
          dom!.pause();
        }else{
          dom!.play()
        }
      };
      // 拖拽
      const isDragging: Ref<boolean> = ref(false);
      const startX: Ref<number> = ref(0);
      const startY: Ref<number> = ref(0);
      const left: Ref<any> = ref(0);
      const top: Ref<any> = ref(0);

      function handleDragStart(event: MouseEvent | TouchEvent) {
        event.preventDefault();

        const clientX = getClientX(event);
        const clientY = getClientY(event);

        isDragging.value = true;
        startX.value = clientX;
        startY.value = clientY;
      }

      function handleDrag(event: MouseEvent | TouchEvent) {
        if (isDragging.value) {
          const clientX = getClientX(event);
          const clientY = getClientY(event);

          const offsetX = clientX - startX.value;
          const offsetY = clientY - startY.value;

          left.value += offsetX;
          top.value += offsetY;

          startX.value = clientX;
          startY.value = clientY;
        }
      }

      function handleDragEnd() {
        isDragging.value = false;
      }

      function getClientX(event: MouseEvent | TouchEvent): number {
        return 'touches' in event ? event.touches[0].clientX : event.clientX;
      }

      function getClientY(event: MouseEvent | TouchEvent): number {
        return 'touches' in event ? event.touches[0].clientY : event.clientY;
      }

      onMounted(() => {
        const container = document.querySelector('.draggable-container') as HTMLElement;
        const containerRect = container.getBoundingClientRect();
        left.value = window.innerWidth - 250;
        top.value = window.innerHeight - 150;
        document.addEventListener('mousemove', handleDrag);
        document.addEventListener('mouseup', handleDragEnd);
        document.addEventListener('touchmove', handleDrag);
        document.addEventListener('touchend', handleDragEnd);
      });

      onUnmounted(() => {
        document.removeEventListener('mousemove', handleDrag);
        document.removeEventListener('mouseup', handleDragEnd);
        document.removeEventListener('touchmove', handleDrag);
        document.removeEventListener('touchend', handleDragEnd);
      });

      return {
        musicSrc:"http://downsc.chinaz.net/files/download/sound1/201206/1638.mp3",
        isOpen,
        handopen,
        visible,
        loginVisible,

        showloginmodal,
        videoVisible,
        showvideomodal,
        showModal,
        handleOk,
        ...toRefs(state),
        toggleCollapsed,
        handleClose,

        ggmvisible,
        ggmvisiblehandleOk,
        ggmvisiblehandleClose,
        jh,
        jhl,
        audio,
        isPlay,
        handlePlayMusic,
        isMobile,
        isJoin,
        closeSubscription,
        isSubscription,

        isDragging,
        left,
        top,
        handleDragStart,
      };
    },
  });
  