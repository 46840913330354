
import { defineComponent, ref } from "vue";
import {
  queryBalancesByIdAndSymbol,
  myqueryBalancesByIdAndSymbol,
} from "@/api";
import { balanceFormat } from "@/utils";

export default defineComponent({
  props: {
    accountId: {
      type: Number,
      required: true,
    },
    isMe: {
      type: Boolean,
      required: true,
    },
  },
  components: {},
  setup(props: any, context: { emit: (arg0: string, arg1: string) => void }) {
    const loading = ref(true);

    const handleSellClick = (tokenId: string) => {
      context.emit("eventSell", tokenId);
    };

    const handleBuyClick = (tokenId: string) => {
      context.emit("eventBuy", tokenId);
    };

    const handleSendToClick = (e) => {
      context.emit("eventSendTo",e);
    };

    const handleExchangeWithClick = (tokenId: string) => {
      context.emit("eventExchangeWith", tokenId);
    };

    const handleCrossChainClick = (e) => {
      context.emit("eventCrossChainSend",e);
    };

    const balanceInfo = ref();

    queryBalancesByIdAndSymbol(props.accountId).then((res) => {
      //wang
      let a = res.data;
      myqueryBalancesByIdAndSymbol(props.accountId).then((resp) => {
        if(a.account?.assets?.length<1){
          a.account.assets=[{amount:"0"}]
        }
        a.account?.assets.push(resp.data?.account?.assets[0]);
        balanceInfo.value = res.data;
        loading.value = false;
      });
    });

    return {
      loading,
      balanceInfo,
      handleSellClick,
      handleBuyClick,
      handleSendToClick,
      handleExchangeWithClick,
      handleCrossChainClick,
      balanceFormat,
    };
  },
});
