import { Transaction_Status } from "../types/ICommon";
import { useEthers } from "vue3-dapp-boot";

const TRANSACTION_STATUS_STEP = 2000;
const TRANSACTION_STATUS_TIMES = 20;

export class TransactionLooper {
  transactionHash: string;
  description: string;
  status: Transaction_Status;
  date: string;
  counter: number;
  onTransactionSuccess: Function;

  constructor(
    txHash: string,
    description: string,
    status: Transaction_Status,
    onTransactionSuccess: Function,
    date: string
  ) {
    this.transactionHash = txHash;
    this.description = description;
    this.status = status;
    this.counter = 0;
    this.date = date;
    this.onTransactionSuccess = onTransactionSuccess;
    this.start();
  }

  start(): void {
    if (this.counter >= TRANSACTION_STATUS_TIMES) {
      return;
    }
    setTimeout(() => {
      this.counter++;
      this.fetch();
    }, TRANSACTION_STATUS_STEP);
  }

  fetch(): void {
    const { provider } = useEthers();
    if (!provider.value) {
      return;
    }
    provider.value
      .getTransactionReceipt(this.transactionHash)
      .then((receipt) => {
        console.log("loop ...");
        if (receipt && receipt.status === 1) {
          this.status = Transaction_Status.Success;
          this.onTransactionSuccess(receipt);
        } else if (receipt && receipt.status === 0) {
          this.status = Transaction_Status.Error;
          this.onTransactionSuccess(receipt);
        } else {
          this.start();
        }
      });
  }
}
