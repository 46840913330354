import { createApp } from "vue";
import App from "./App.vue";

import { createPinia } from "pinia";
import PersistedState from "pinia-plugin-persistedstate";

import Antd from "ant-design-vue";
import "./styles/index.less";

import router from "./router";

import lazyPlugin from 'vue3-lazy'

import { VueDapp } from "vue3-dapp-boot";
import { ethers } from "ethers";
(window as any).ethers = ethers;

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

import infiniteScroll from 'vue3-infinite-scroll-good'

import '@/assets/font/font.css';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VConsole from 'vconsole';

// if (process.env.NODE_ENV === 'development') {
//   new VConsole();
// }


const app = createApp(App)

app.config.globalProperties.$accert=(window as any).acert
app.config.globalProperties.$metaverses = ['0x7db439e5862931c53eb3a788f07455373ecaf6eb',
  '0x22704345C8649CA4b9f970c616cE7fb5fB1Fdab9'
];
app.config.globalProperties.$config={
  metaverse:'0x7db439e5862931c53eb3a788f07455373ecaf6eb',
  contracts:{
  GGM:"0xeea9ca3094b5955dac9451b8c288fe7d9268a53a",
      EQPT:"0x3ac2623908f18246655ec9d66bf23f28e00ab3f8",
      GCoin:"0x96c48dd49da50c92aa166d40ba787a1fcb78d2fd",
      MOGA:"0x78bf39967b5a68f2cd2381ddf7457a1b1ce47d07",
      MonsterGalaxy:"0x972a2467ab4c92a39ce4476718b3006a2ee6cb81"
},
  services:{
  subgraph:"https://testapi.gon.network/subgraphs/name/acert/v4",
    operator:"https://testapi.gon.network/operator",
    userBackend:"https://testapi.gon.network/auth/v1",
    gAuthPage:"https://testid.gon.network/auth/v1_0/"
}
}
// 正式
// app.config.globalProperties.$config={
//     metaverse: "0x22704345C8649CA4b9f970c616cE7fb5fB1Fdab9",
//     contracts: {
//         GGM: "0x87ae93fca495780cbff5d6a10e0386e1a6c8581b",
//         EQPT: "0x83cb0f62fb3009455d3461c1035b8ee6854c8fec",
//         GCoin: "0xe7a552bea186f0c4b4718648fafeaf8d6c85cd5e",
//         MOGA: "0xc7bdb4b97a81abc37067a0f0c9d3751f2ffbc6e3",
//         MonsterGalaxy: "0x40C7214f2d1312A492DBBc77Bb1D851431ad1678"
//     },
//     services: {
//         subgraph: "https://api.gon.network/subgraphs/name/acert/v4",
//         operator: "https://api.gon.network/operator",
//         userBackend: "https://api.gon.network/auth/v1",
//         gAuthPage: "https://id.gon.network/auth/v1_0/"
//     }
// }
app.config.globalProperties.$p=app.config.globalProperties.$accert.Provider.init(app.config.globalProperties.$config);
app.config.globalProperties.$g=new app.config.globalProperties.$accert.GAuth(app.config.globalProperties.$config.services);


app.use(Antd);
app.use(createPinia().use(PersistedState));
app.use(lazyPlugin,{
  loading: '/assets/default.png',
  error: '/assets/error.png'
});
app.use(router);
app.use(VueDapp);
app.use(PerfectScrollbar)
app.use(infiniteScroll)
app.mount("#app");

//路由跳转后，页面回到顶部
router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});
