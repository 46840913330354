
import { defineComponent, watchEffect, ref } from "vue";
import { useClipboard } from "@vueuse/core";
import { Transaction_Status } from "../types/ICommon";
import { useTransactionStore } from "@/wallet/store/transaction";
import Spin from "../components/Spin.vue";
import { useEthers, shortenAddress, useWallet } from "vue3-dapp-boot";
import { openInExplorer, openAccountInExplorer } from "../utils";
import {
  CopyOutlined,
  ToTopOutlined,
  LogoutOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons-vue";
import { useLoopers } from "@/wallet/hooks/useLoopers";
import moment from "moment";
import { isMobile } from "@/utils";

export default defineComponent({
  name: "WalletAccount",
  components: {
    Spin,
    CopyOutlined,
    ToTopOutlined,
    LogoutOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
  },
  setup(props, context) {
    const { isActivated, address, balance, avatar, chainId } = useEthers();
    const transactionStore = useTransactionStore();
    const { txs, clearAllTransaction } = useLoopers();
    const { copy } = useClipboard();

    const {
      disconnectWallet,
    } = useWallet();

    const placement = ref<string>("right");

    watchEffect(async () => {
      if (isMobile) {
        placement.value = "bottom";
      }
    });

    return {
      moment,
      // NETWORK_NAME,
      openInExplorer,
      openAccountInExplorer,
      encrypt: shortenAddress,
      Transaction_Status,
      account: address,
      txs,
      clearAllTransaction,
      copy,
      disconnectWallet,
      isActivated,
      chainId,
      placement,
    };
  },
});
