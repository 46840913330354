
import { defineComponent, ref } from "vue";
import { Assets_Type, Transaction_Type, User_Type } from "@/constants/index";
import { shortenAddress } from "@/utils";

export default defineComponent({
  props: {
    accountId: {
      type: String,
      required: true,
    },
    address: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  setup(props: any, context: { emit: (arg0: string, arg1: any) => void }) {
    const loading = ref(false);

    const handleClick = () => {
      loading.value = !loading.value;
    };

    var jazzicon = require("@metamask/jazzicon");
    const iconNode = jazzicon(40, parseInt(props.address.slice(2, 10), 16));
    const avatar = document.createElement("div").appendChild(iconNode);

    interface User {
      accountId: string;
      address: string;
    }

    const select = () => {
      let user: User = {
        accountId: props.accountId,
        address: props.address,
      };
      context.emit("eventSelect", user);
    };

    const clear = () => {
      // console.log("clear")
      let user: User = {
        accountId: props.accountId,
        address: props.address,
      };
      context.emit("eventClear", user);
    };

    return {
      loading,
      Assets_Type,
      User_Type,
      Transaction_Type,
      avatar,
      select,
      clear,
      handleClick,
      shortenAddress,
    };
  },
});
