import apolloClient from "@/utils/graphsqlv2";
import gql from "graphql-tag";
import {accountStore} from "@/store";
import { storeToRefs } from "pinia";
import {getTimestamp} from "@/utils";

const METAVERSE = process.env.VUE_APP_METAVERSE?.toLocaleLowerCase();
const WORLD = process.env.VUE_APP_WORLD?.toLocaleLowerCase();

// const store = accountStore();
// const { getAccountId } = storeToRefs(store);
export function queryBath() {
    const store = accountStore();
    const { getAccountId } = storeToRefs(store);
    const id = METAVERSE + "@" + getAccountId.value;
    const GET_BALANCE = gql`
    query Bath($batchId: Int) {
  batches(where: {id: 1}) {
    totalNft
    totalSold
    totalOnSale
    totalAction
  }
}
  `;
    return apolloClient.query({
        query: GET_BALANCE,
        variables: {
            id,
        },
    });
}
export function querymogaNFTs(first: number,skip:number) {
    console.log(first,680999)
    const store = accountStore();
    const { getAccountId } = storeToRefs(store);
    const id = METAVERSE + "@" + getAccountId.value;
    const now = Math.floor(Date.now() / 1000);
    const GET_BALANCE = gql`
    query NFTs($first:Int!,$skip:Int!,$now:Int!) {
  nfts(
    first: $first
    skip: $skip
    where: {batch_: {endTimestamp_gt: $now}}
  ) {
    id
    tokenId
    price
    url
    logo
    image
    rarity
    isSold
    level {
      name
      level
    }
    batch {
      endTimestamp
    }
  }
}
  `;
    return apolloClient.query({
        query: GET_BALANCE,
        variables: {
            id,
            first,
            skip,
            now
        },
    });
}
export function queryHistory(first: number,skip:number,) {
    const store = accountStore();
    const { getAccountId } = storeToRefs(store);
    const id = METAVERSE + "@" + getAccountId.value;
    const GET_BALANCE = gql`
    query History($first: Int, $skip: Int) {
  actions(first: $first, skip: $skip, orderBy: timestamp, orderDirection: desc) {
    toAccount
    transaction
    timestamp
    nft {
      tokenId
      price
      name
      logo
      rarity
    }
  }
}
  `;
    return apolloClient.query({
        query: GET_BALANCE,
        variables: {
            id,
            first,
            skip
        },
    });
}

export function queryOrders(first: number,skip:number,status:string,pairId:string) {
    const store = accountStore();
    const { getAccountId } = storeToRefs(store);
    const id = METAVERSE + "@" + getAccountId.value;
    const GET_BALANCE = gql`
    query Orders($first: Int, $skip: Int, $status: String, $pairId: String) {
  orders(first: $first, skip: $skip, orderBy: price, where: {status: $status}) {
    id
    maker
    taker
    side
    totalAmount
    price
    listingTime
    expirationTime
    status
  }
  p2Ppair(id: $pairId) {
    id
    validOrderCount
  }
}
  `;
    return apolloClient.query({
        query: GET_BALANCE,
        variables: {
            first,
            skip,
            status,
            pairId
        },
    });
}
