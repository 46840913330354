
import tabbar from "../../components/tabbar/tabbar.vue";
import tabbarItem from "../../components/tabbar/tabbarItem.vue";
export default {
  name: "customTabbar",
  components: {
    tabbar,
    tabbarItem,
  },
};
