<template>
<div class="salebg">
<!--  <img class="topimg" src="../../assets/mogasalebackground.png">-->
  <div class="title JungleAdventurer text-2xl sm:text-4xl">
    Monster Galaxy MOGA Sale
  </div>



<!--  <div style="transform: translate3d(0px, 0px, -100px) rotateX(0deg) rotateY(-50deg) scale(1);transform-style: preserve-3d;width: 200px;height: 200px;background: #01bd35"></div>-->
  <div class="time flex justify-center">
    <div class="day item">
      <div class="JungleAdventurer text-2xl">{{ days }}</div>
      <div class="roboto">DAYS</div>
    </div>
    <div class="hour item">
      <div class="JungleAdventurer text-2xl">{{ hours }}</div>
      <div class="roboto">HOUR</div>
    </div>
    <div class="min item">
      <div class="JungleAdventurer text-2xl">{{ minutes }}</div>
      <div class="roboto">MIN</div>
    </div>
    <div class="sec item">
      <div class="JungleAdventurer text-2xl">{{ seconds }}</div>
      <div class="roboto">SEC</div>
    </div>
  </div>
  <div class="card-list">
<!--      <div class="cardlist-li " >-->
<!--      </div>-->
<!--    <div class="cardlist-li">-->
<!--    </div>-->
    <div class="cardlist-li">
    </div>
    <div class="cardlist-li">
    </div>
    <div class="cardlist-li">
    </div>
  </div>
  <div class="gtcard text-center" style="position: absolute;left: calc(70vw)">
    <div class="text-base roboto" style="color: #ffda69">UPCOMING SALE</div>
    <div class="JungleAdventurer djs2 flex items-center justify-center" >{{daysing}} D {{hoursing}}H {{minutesing}}M</div>
    <img class="ml-8" width="148" src="../../assets/web/new/v2/cards.png">
  </div>
    <swiper
        :effect="'coverflow'"
        :grabCursor="true"
        :freeMode="true"
        :slidesPerView="3"

        :navigation="{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev', }"
        :coverflowEffect="{
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,

    }"

        :modules="modules"
        class="mySwiper"
    >
      <swiper-slide
      ><img
          src="../../assets/web/new/v2/card02.png" /></swiper-slide
      ><swiper-slide
    ><img
        src="../../assets/web/new/v2/card03.png" /></swiper-slide
    ><swiper-slide
    ><img
        src="../../assets/web/new/v2/card04.png" /></swiper-slide
    >
<!--      <swiper-slide-->
<!--    ><img-->
<!--        src="../../assets/salecard004.png" /></swiper-slide-->
<!--    ><swiper-slide-->
<!--    ><img-->
<!--        src="../../assets/salecard005.png" /></swiper-slide-->
<!--    >-->
    </swiper>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>

  <div class="email">
    <input type="email" size="mini" v-model="email" name="email" id="email" class="roboto mb-3 bg-gray-50 border border-gray-300  text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 text-white" placeholder="YOUR EMAIL ADDRESS" required>

  </div>
  <div class="button">
    <img class="buttonimg h-scale-101 cursor-pointer" src="../../assets/button.png" @click="subscribeEmail">
  </div>
  <div class="text roboto">
    Notify me when Genesis Moga are available
  </div>
</div>
  <div @click="handissucesss" v-if="issucesss"  style="position: fixed;top:0;bottom: 0;left: 0;right: 0;background: rgba(0,0,0,.7);display: flex;align-items: center;justify-content: center;z-index: 999">
    <div class="success-bg">
      <img width="400"  src="../../assets/web/modal-sub.png">
    </div>
  </div>
</template>

<script>
// const metaverses = ['0x7db439e5862931c53eb3a788f07455373ecaf6eb',
//   '0x22704345C8649CA4b9f970c616cE7fb5fB1Fdab9'
// ];
// let g;
//
// const config = {
//   metaverse: metaverses[0],
//   contracts: {
//     GGM: '0xeea9ca3094b5955dac9451b8c288fe7d9268a53a',
//     EQPT: '0x3ac2623908f18246655ec9d66bf23f28e00ab3f8',
//     GCoin: '0x96c48dd49da50c92aa166d40ba787a1fcb78d2fd',
//     MonsterGalaxyTest: '0x972a2467ab4c92a39ce4476718b3006a2ee6cb81',
//   },
//   services: {
//     subgraph: 'https://database1.gaiaopen.network/subgraphs/name/acert/moga-acert-v2-sdk',
//     operator: 'https://database1.gaiaopen.network/operatorV2',
//   },
// };
// const p = window.acert.Provider.init(config);
// console.log(p,789)
import axios from "axios";
import {ref} from "vue";
import { message } from 'ant-design-vue';

import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination } from "swiper";
import 'swiper/css/navigation';
import { Navigation } from 'swiper';

import {getmogasubscribetime} from '@/api/comapi'
export default {
name: "Salemoga",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      countdown: null,
      targetDate: new Date('2023-07-07'), // 倒计时的目标日期
      targetDateing:new Date('2023-07-07'), // 倒计时的目标日期
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      daysing: 0,
      hoursing: 0,
      minutesing: 0,
      email:'',
      issucesss:0,
    };
  },
  setup(){
    return {
      modules: [EffectCoverflow, Pagination,Navigation],
    };

  },
  mounted() {
    this.activeTime()
  },
  methods: {
    activeTime: function () {
      const cd1api = process.env.VUE_APP_CD_API;
      axios.get(cd1api + 'getmogasubscribetime', {

      })
          .then((d) => {
            // this.targetDate = new Date(d.data.message.endtime)
            this.targetDate = d.data.message.endtime1*1000
            this.targetDateing=d.data.message.endtime2*1000

            this.startCountdown();
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    Getmogasubscribetime(){
      getmogasubscribetime().then((response) => {

      })
    },
    handissucesss(){

      this.issucesss=0
    },
    subscribeEmail(){
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      console.log(this.email.match(emailRegex),79)
      if(this.email.match(emailRegex)==null){
        message.error({
          content: () => 'Invalid Email',
          class: 'custom-class',
          icon:' ⚠',
        })
        return
      }
      const cd1api = process.env.VUE_APP_CD_API;
      axios.post(cd1api + 'mogasubscribe', {email:this.email,id:1},{headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
          .then((res) => {
            let data=res.data
            if(data.status==100){
              this.issucesss=1
              message.success({
                content: () => data.message,
                class: 'custom-class',
                icon:' ',
              })
            }else{
              message.error({
                content: () => data.message,
                class: 'custom-class',
                icon: ' ',
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    startCountdown() {
      this.countdown = setInterval(() => {
        const now = new Date();

        const timeDifference = this.targetDate - now;
        this.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
        if (timeDifference < 0) {
          // clearInterval(this.countdown);
          this.days = 0;
          this.hours = 0;
          this.minutes = 0;
          this.seconds = 0;
        }

        const timeDifferenceing = this.targetDateing - now;
        this.daysing = Math.floor(timeDifferenceing / (1000 * 60 * 60 * 24));
        this.hoursing = Math.floor((timeDifferenceing % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutesing = Math.floor((timeDifferenceing % (1000 * 60 * 60)) / (1000 * 60));
        if (timeDifferenceing < 0) {
          clearInterval(this.countdown);
          this.daysing = 0;
          this.hoursing = 0;
          this.minutesing = 0;
        }

      }, 1000);
    }
  },
  beforeUnmount() {
    clearInterval(this.countdown);
  }
}
</script>

<style scoped lang="less">
.h-scale-101:hover{
  transform: scale(1.1);
}
::v-deep .custom-class .ant-message-custom-content .ant-message-notice-content{
  display: flex !important;align-items: center !important;
}
.djs2{
  background-image: url("../../assets/web/new/v2/upcomingsaleicon.png");background-repeat: no-repeat;background-size: cover;background-position: center;
  aspect-ratio: 270/74;
}
.salebg{
color: #fff;
  position: relative;
  background-image: url("../../assets/web/Moga-Sale-Background.png");background-repeat: no-repeat;background-size: 100%;width: 100%;aspect-ratio: 2560 / 1208;min-height: 520px;
  display: flex;align-items: center;
  //margin-top: 64px;
}
.success-bg{
  background-image: url("../../assets/web/gloweffect.png");background-repeat: no-repeat;background-size: 100%;
}

.topimg{width: 69vw;margin: 0 auto}
.title{text-align: center;
  position: absolute;bottom: 72%;left: 0;right: 0;margin: auto ;text-shadow: 0 0 10px #ffffff	//设置发光效果
}
.time{
  position: absolute;bottom: 59%;left: 0;right: 0;margin: auto ;
  .item{margin:  0 5px;
    background-image: url("../../assets/days_.png");background-repeat: no-repeat;background-size: 100%;
    //width: 5%;
    width: 67px;
    aspect-ratio: 134 / 144;flex-direction: column;justify-content: center;
    display: flex;align-items: center;
  }
}
.text{text-align: center;
  position: absolute;bottom: 1%;left: 0;right: 0;margin: auto ;color: #414143;
}
.button{
}
.email input{background-color: rgba(0,0,0,.68);border: none}
.buttonimg{
  max-width: 422px;width: 16vw;
  position: absolute;bottom: 6%;left: 0;right: 0;margin: auto ;min-width: 120px
}
.email input{width: 30%;left: 0;right: 0;margin: auto ;bottom: 20%;position: absolute}
.card-list{
  display: flex;
  width: 69vw;
  left: 0;right: 0;margin: auto ;
  flex-flow: row wrap;
  -webkit-box-pack: center;
  justify-content: center;
  position: absolute;bottom: 28%;
}
.cardlist-li:nth-child(1){
  background: url("../../assets/web/new/v2/card02.png") no-repeat;background-size: 100%;
}
.cardlist-li:nth-child(2){
  background: url("../../assets/web/new/v2/card03.png") no-repeat;background-size: 100%;
}
.cardlist-li:nth-child(3){
  background: url("../../assets/web/new/v2/card04.png") no-repeat;background-size: 100%;
}
.cardlist-li:nth-child(4){
  background: url("../../assets/salecard04.png") no-repeat;background-size: 100%;
}
.cardlist-li:nth-child(5){
  background: url("../../assets/salecard05.png") no-repeat;background-size: 100%;
}
//.cardlist-li:nth-child(4){
//  transition-duration: 0ms;
//  transform: translate3d(0px, 0px, -100px) rotateX(-9deg) rotateY(-142deg) rotateZ(-3deg) scale(1.1);
//}
.cardlist-li{
  margin: 15px;width: 13%;
  aspect-ratio: 309 / 409;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  display: none;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  //width: 300px;
  //height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

//定义箭头默认颜色
.swiper-button-prev,
.swiper-button-next {
  display: none;
  color: grey;
  &:hover {
    color: skyblue;
  }
}
.swiper-button-prev {
  left: 60px;
  background-image: url("../../assets/web/new/arrowleft.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  //使用iconfont字体对应的unicode作为箭头图案
  &::after {
    content: '';
  }
}
.swiper-button-next {
  right: 60px;
  background-image: url("../../assets/web/new/arrowright.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &::after {
    content: '';
  }
}
//定义箭头样式
.swiper-button-prev::after,
.swiper-button-next::after {
  //指定字体采用iconfont
  font-family: iconfont;
  font-weight: 800;
  font-size: 30px;
}

@media (max-width: 768px) {
  .title{
    top: 2%;
  }
  .time{bottom: 76%}
  .card-list{width: 100vw}
  .email input{width: 80%;bottom: 16%}
  .topimg{width: 100vw;margin: 0 auto}
  .swiper-button-prev{display: block}
  .swiper-button-next{display: block}
  .card-list{bottom: 32%}
  .cardlist-li{width: 12%;margin: 10px}
  .swiper{display: block}
  .gtcard{display: none}
  .card-list{display: none}
  .salebg{
    background-image: none;
  }
  .mySwiper{
    //padding-top: 120px;
    background-image: url("../../assets/mogasalebackground.png");background-repeat: no-repeat;background-size: 100%;
  }
  .mm-swiper{

  }
}
</style>
