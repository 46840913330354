
import { defineComponent, ref } from "vue";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import { accountStore } from "@/store";
import { storeToRefs } from "pinia";

import { User_Type, IUser } from "@/constants";
import { getPlacement } from "@/utils";
import { verifyAuthorization } from "@/api";

import User from "@/components/card/user.vue";
import NoData from "@/components/common/noData.vue";

export default defineComponent({
  name: "Send To Drawer",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    }
    // type: {
    //   type: Number,
    //   required: true,
    // },
    // assetType: {
    //   type: Number,
    //   required: true,
    // },
  },
  components: {
    User,
    NoData,
    PerfectScrollbar,
  },
  setup(
    props: any,
    context: { emit: (arg0: string, arg1: any) => void }
  ) {

    const accountIdValue = ref<string>("");
    const addressValue = ref<string>("");
    const checked = ref(false);
    const placement = ref<string>(String(getPlacement()));

    const store = accountStore();
    const contactsList = ref([] as Array<IUser>);

    const { getContacts } = storeToRefs(store);

    const contacts = getContacts;
    const showErr = ref(false);

    const handleSelectClick = (item : any) => {
      let retObj: IUser = {
        accountId: item.accountId,
        address: item.address,
        type: props.type,
      };
      context.emit("event", retObj);
    };

    const handleNextClick = () => {

      let retObj: IUser = {
        accountId: accountIdValue.value,
        address: addressValue.value,
        type: props.type,
      };

      verifyAuthorization(accountIdValue.value, addressValue.value).then(
        (res) => {
          if (res?.data?.authAddresses.length > 0) {
            if (checked.value) {
              if (contactsList.value.indexOf(retObj) == -1) {
                contactsList.value.push(retObj);
              }

              const obj: { [key: string]: boolean } = {};
              contactsList.value = contactsList.value.reduce<IUser[]>(
                (item, next) => {
                  if (!obj[next.address]) {
                    item.push(next);
                    obj[next.address] = true;
                  }
                  return item;
                },
                []
              );

              // store.$patch({
              //   contacts: contactsList.value,
              // });
            }
            context.emit("event", retObj);
          }
        }
      );
    };
    return {
      accountIdValue,
      addressValue,
      checked,
      placement,
      contacts,
      showErr,
      User_Type,
      NoData,
      handleSelectClick,
      handleNextClick,
    };
  },
});
