import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "tabbar-item",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.itemClick && $setup.itemClick(...args))),
    style: _normalizeStyle($setup.selectedBackgroundColor),
    class: "rounded-full m-1 cursor-pointer"
  }, [
    _createElementVNode("div", {
      style: _normalizeStyle($setup.selectedText)
    }, [
      _renderSlot(_ctx.$slots, "item-text", {}, () => [
        _createTextVNode("文本")
      ], true)
    ], 4)
  ], 4))
}