import { TransactionReceipt } from "@ethersproject/providers";

type Pick<T, K extends keyof T> = {
  [P in K]: T[P];
};

export enum Transaction_Status {
  Wallet = "wallet",
  Pending = "pending",
  Complete = "complete",
  Success = "success",
  Error = "error",
  Reject = "reject",
}

export type BroadcastResult = TransactionReceipt & {
  hash: string;
  confirmations: number;
};

export interface ITransaction {
  transactionHash: string;
  description: string;
  status: Transaction_Status;
  date: string;
}

export const CHAIN_ID = 10024;
