import Decimal from 'decimal.js';
import { DEFAULT_PRECISION, DEFAULT_USER_PRECISION, MAX_PRECISION } from '../constants';
import {ethers} from "ethers";
import { BigNumberish } from "ethers";

(window as any).Decimal = Decimal;
Decimal.set({ precision: DEFAULT_PRECISION });
Decimal.set({ toExpPos: MAX_PRECISION });
Decimal.set({ rounding: Decimal.ROUND_DOWN });

export function toDecimalPlaces(
  value: Decimal.Value,
  precision: number = DEFAULT_USER_PRECISION
) {
  try {
    const _value = new Decimal(value).mul(Math.pow(10, precision)).floor();
    return _value.div(Math.pow(10, precision)).toDecimalPlaces(precision, Decimal.ROUND_UP).toString();
  } catch (e: any) {
    return String(value || '-');
  }
}

export function toString(
  value: Decimal.Value,
  precision: number = DEFAULT_USER_PRECISION,
  roundingMode: Decimal.Rounding = Decimal.ROUND_DOWN
): string {
  try {
    return new Decimal(value).toSignificantDigits(precision, roundingMode).toString();
  } catch (e: any) {
    return String(value || '-');
  }
}

export function toAmountFloor(
  value: Decimal.Value,
  precision: number = DEFAULT_USER_PRECISION
): string {
  return toString(value, precision, Decimal.ROUND_DOWN);
}

export function toAmountCeil(value: Decimal.Value, precision: number = DEFAULT_USER_PRECISION) {
  return toString(value, precision, Decimal.ROUND_DOWN);
}

export function toFormatEthers(value: BigNumberish): string {
  if (value) {
    return toAmountFloor(ethers.utils.formatEther(value));
  } else {
    return '-';
  }
}
