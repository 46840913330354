<template>
  <div>
  </div>

</template>

<script>
import {getDeadline, getTimestamp} from "@/utils";



import {defineComponent, getCurrentInstance,defineExpose,ref} from "vue";
import { accountStore } from "@/store";
import { apiBridgev2 } from "@/api/bridge";
import {
  getActionBodyBuyNFTBWO,
  isTrustWorld,
  getActionBodyTrustWorldBWO,
  sendActionTrustWorldBWO, sendActionBuyNFTBWO
} from "@/constants";
export default defineComponent({
name: "couldwallet",
  setup(props, context){
  console.log(props,789123)
    const { proxy } = getCurrentInstance()
    // const p = proxy.$accert.Provider.init(proxy.$config);

    const t = new proxy.$accert.Token(proxy.$p, 'EQPT');
    const t1 = new proxy.$accert.Token(proxy.$p, 'GCoin');
    const tggm = new proxy.$accert.Token(proxy.$p, 'GGM');

    let g=proxy.$g;
    // g = new proxy.$accert.GAuth(proxy.$config.services);
    if (!g.hasLoggedIn()) {
      console.log(173378)
      // window.location.href = `g_auth.login.html`; // 跳转到登录页面
    }
    g.metaverse = proxy.$config.metaverse; // 设置当前metaverse
    // g.renderModal('gAuthModalRoot'); // 初始化弹出窗口
    g.render('gAuthRoot', 'gAuthPopperBtn', 'auto');
    /* 初始化显示当前用户信息的窗口 */
    // g.renderPopper('gAuthPopperRoot', 'gAuthPopperBtn', 'auto');


    g.onLoginComplete(() => {
      context.emit('loging',true)
      a()
    });
    g.onLogoutComplete(() => {
      // window.location.href = `g_auth.login.html`; // 登出成功后跳转到登录页面
    });
    const store = accountStore();
    const deadline = getDeadline();
    async function  a(){
      try {
        await g.enableCloudwallet();
      } catch (e) {
        if (e.code === 1024) {
          console.info('Already enabled, %o', e);
        } else {
          console.error('enableCloudwallet error, %o', e);
        }
      }
      await t1.getDecimals();

      const user =await g.userInfo()
      sessionStorage.setItem('cloud_wallet',user.cloud_wallet)


      const account =await user.getAccount(g.metaverse);


      if (account) {
        // todo
        // if(account.is_enable_cloud_wallet==false){
        //
        // }else{
        //
        // }

        store.$patch({
          accountId: account.id,
        });
        await localStorage.setItem('account',JSON.stringify(account))
        await sessionStorage.setItem('tid',account.id)


        const data = ref(isTrustWorld);
        data.value.id = getTimestamp();
        const params = [
          {
            id: account.id,
            world: "0x40C7214f2d1312A492DBBc77Bb1D851431ad1678"
          },
        ];
        data.value.params = params;
        apiBridgev2(data.value).then((res) => {
          if(res.result==false){
            trust(account)
          }else{
            context.emit('logined',true)
          }
        })
        getBalance(account.id);
      }

    }
    function trust(account){
      const data = ref(getActionBodyTrustWorldBWO);
      data.value.id = getTimestamp();
      const params = [
        {
          sender: account.authes[0].address,// 签名的账户地址
          world: "0x40C7214f2d1312A492DBBc77Bb1D851431ad1678",// world合约地址
          deadline: deadline ,
          isTrustWorld: true
        },
      ];
      data.value.params = params;
      apiBridgev2(data.value).then((res) => {
        could_signTypedData(res)
      })
    }
    const could_signTypedData = async (res) => {
      const signature = await g.sign(res.result.typedData);
      const data = ref(sendActionTrustWorldBWO);
      data.value.id = getTimestamp();
      const params = [
        {
          sender:res.result.typedData.message.sender,// 签名的账户地址
          world: "0x40C7214f2d1312A492DBBc77Bb1D851431ad1678",// world合约地址
          deadline: deadline ,
          isTrustWorld: true,
          hash:res.result.hash,
          signature:signature
        },
      ];
      data.value.params = params;
      apiBridgev2(data.value).then((res) => {
        context.emit('logined',true)
      })
    }
    async function getBalance(id) {
      console.log(id,12334)
      const balance = await t1.balanceOf(id);
      const balance2 = await tggm.balanceOf(id);
      console.log(balance2,7890)
      console.log(t1.getDecimals(),7990)
      const bb=t1.fromWei(balance)
      console.log(balance,78901)
    }
    const login= () => {
      g.login();
    }
    const logout= () => {
      g.logout();
      localStorage.removeItem('account')
      sessionStorage.removeItem('tid')
      context.emit('logined',false)
    }


    defineExpose({ login })

    return {
      login,
      logout
    }


  },

})

</script>

<style scoped>

</style>
