import {defineStore} from "pinia";
import {isEmpty} from "lodash";
import {BroadcastResult, ITransaction, Transaction_Status} from "@/wallet/types/ICommon";
import {useLoopers} from "@/wallet/hooks/useLoopers";
import moment from 'moment';

interface txProps {
  description: string;
  contractFn: Function;
  onTransactionCompleted?: Function
}

export const useTransactionStore = defineStore("transaction", {
  state: () => {
    return {
      transactionStatus: Transaction_Status.Wallet,
      visibleConfirmInWallet: false,
      visibleRejectInWallet: false,
      visibleTransactionSubmitted: false,
      visibleSuccess: false,
      visibleFail: false,
      description: "",
      broadcastResult: {} as BroadcastResult,
      onTransactionClosed: null as Function | null,
      onReload: null as Function | null
    };
  },
  getters: {
    hasCurrentTx() {
      if (isEmpty(this.broadcastResult)) {
        return false;
      }
      return true;
    }
  },
  actions: {
    async sendTransaction(config: txProps) {
      this.start(config.description);
      try {
        const result = await config.contractFn();
        console.log(JSON.stringify(result));
        this.submitTransaction(result);
        if (config.onTransactionCompleted) {
          this.onTransactionClosed = config.onTransactionCompleted;
        }
      } catch (error: any) {
        if (error.message.includes("User denied")) {
          this.rejectTransaction();
        } else {
          console.error(error.message);
        }
      }
    },
    start(description: string) {
      this.$patch({
        transactionStatus: Transaction_Status.Wallet,
        visibleConfirmInWallet: true,
        description,
      });
    },
    submitTransaction(broadcastResult: BroadcastResult) {
      this.$patch({
        transactionStatus: Transaction_Status.Pending,
        visibleConfirmInWallet: false,
        visibleTransactionSubmitted: true,
        broadcastResult,
      });

      const { addTransaction } = useLoopers();
      addTransaction(<ITransaction>{
        transactionHash: broadcastResult.transactionHash || broadcastResult.hash,
        status: Transaction_Status.Pending,
        description: this.description,
        date: moment().toString()
      });
    },
    rejectTransaction() {
      this.visibleConfirmInWallet = false;
      this.visibleRejectInWallet = true;
    },
  },
});
