import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "-mt-16 mb-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GGM = _resolveComponent("GGM")!
  const _component_MogaNFT = _resolveComponent("MogaNFT")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_NFTDetailsDrawer = _resolveComponent("NFTDetailsDrawer")!
  const _component_SendNFTDrawer = _resolveComponent("SendNFTDrawer")!
  const _component_SendTokenDrawer = _resolveComponent("SendTokenDrawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_tabs, {
        type: "card",
        activeKey: _ctx.activeKey,
        "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_tab_pane, {
            key: "1",
            tab: "Monster Galaxy",
            class: "bg-component border-b rounded-b-xl rounded-tr-xl p-2 bg-white text-black space-y-2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_GGM, {
                onEventSendTo: _ctx.openSendToDrawer,
                "account-id": _ctx.cardAccountId,
                "is-me": false
              }, null, 8, ["onEventSendTo", "account-id"]),
              _createVNode(_component_MogaNFT, {
                title: "Moga",
                onEvent: _ctx.openNFTDetailsDrawer,
                type: _ctx.NFT_Type.MOGA,
                "account-id": _ctx.cardAccountId
              }, null, 8, ["onEvent", "type", "account-id"]),
              _createVNode(_component_MogaNFT, {
                title: "Equip",
                onEvent: _ctx.openNFTDetailsDrawer,
                type: _ctx.NFT_Type.EQPT,
                "account-id": _ctx.cardAccountId
              }, null, 8, ["onEvent", "type", "account-id"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["activeKey"])
    ]),
    (_ctx.item != undefined)
      ? (_openBlock(), _createBlock(_component_NFTDetailsDrawer, {
          key: 0,
          visible: _ctx.visibleNFTDetailsDrawer,
          item: _ctx.item,
          "is-me": false,
          onEvent: _ctx.closeNFTDetailsAndOpenSendNFTDrawer,
          onClose: _ctx.hideNFTDetailsDrawer
        }, null, 8, ["visible", "item", "onEvent", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.item != undefined && _ctx.user != undefined)
      ? (_openBlock(), _createBlock(_component_SendNFTDrawer, {
          key: 1,
          visible: _ctx.visibleSendNFTDrawer,
          item: _ctx.item,
          user: _ctx.user,
          onEventClear: _ctx.closeSendNFTDrawerAndOpenSendToDrawer,
          onEventRefresh: _ctx.closeSendNFTDrawerAndRefresh,
          onClose: _ctx.hideSendNFTDrawer
        }, null, 8, ["visible", "item", "user", "onEventClear", "onEventRefresh", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.user != undefined)
      ? (_openBlock(), _createBlock(_component_SendTokenDrawer, {
          key: 2,
          visible: _ctx.visibleSendTokenDrawer,
          user: _ctx.user,
          onEventClear: _ctx.closeSendTokenDrawerAndOpenSendToDrawer,
          onEventRefresh: _ctx.closeSendTokenDrawerAndRefresh,
          onClose: _ctx.hideSendTokenDrawer
        }, null, 8, ["visible", "user", "onEventClear", "onEventRefresh", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}